import { useState } from 'react'
import { useQuery } from 'react-query'
import { isFeatureEnabled } from 'services/featureFlags'

export interface IFeatureFlagOptions {
  date?: string
  queryEnabled?: boolean
}

export const useFeatureFlag = (featureFlagName: string, options?: IFeatureFlagOptions) => {
  const [result, setResult] = useState<boolean | undefined>()

  useQuery(['featureFlag', featureFlagName, options?.date], () => isFeatureEnabled(featureFlagName, options?.date), {
    retry: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    enabled: (options?.date === undefined ? true : Boolean(options.date)) && options?.queryEnabled,
    onSuccess: (data) => setResult(data.data),
    onError: () => setResult(undefined),
  })

  return result
}
