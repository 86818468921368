import {
  API_GET_TRANSACTIONS_LIST,
  API_CREATE_TRANSACTION,
  API_GET_WITHDRAWAL,
  API_CHANGE_TRANSACTION_STATUS,
  API_GET_SUMMARY_WITHDRAWAL,
  API_GET_DEAL_PAYMENTS,
  API_CREATE_DEAL_PAYMENT,
  API_EDIT_TRANSACTION,
  API_APPLY_COMMISSION,
  API_APPLY_PSF,
  API_GET_LEVERAGE_AMOUNT,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import {
  ITransactionListParams,
  ITransactionListRequest,
  ITransactionParamsInitValue,
  ICreateTransactionResponse,
  IWithdrawalGetParams,
  ITransactionChangeStatusValues,
  IWithdrawalListItem,
  IWithdrawalGetSummaryItem,
  IGetDealPaymentsParams,
  IDealPayment,
  IEditTransactionParams,
} from 'typescript/interfaces/transactions'
import { AxiosResponse } from 'axios'
import { IDealPaymentParams } from 'typescript/interfaces/deals'
import { generatePath } from 'react-router-dom'

export const getTransactionList = (params: ITransactionListParams) =>
  privateInstance.get<ITransactionListParams, AxiosResponse<ITransactionListRequest>>(API_GET_TRANSACTIONS_LIST, {
    params: params,
  })

export const getLeverage = (userPageId: string) =>
  privateInstance.get<string, AxiosResponse<number>>(API_GET_LEVERAGE_AMOUNT, {
    params: { userPageId },
  })

export const createTransaction = (values: Partial<ITransactionParamsInitValue>) =>
  privateInstance.post<Partial<ITransactionParamsInitValue>, AxiosResponse<ICreateTransactionResponse>>(API_CREATE_TRANSACTION, values)

export const getWithdrawal = (params: IWithdrawalGetParams) =>
  privateInstance.get<IWithdrawalGetParams, AxiosResponse<Array<IWithdrawalListItem>>>(API_GET_WITHDRAWAL, {
    params: params,
  })

export const changeStatus = (values: ITransactionChangeStatusValues) =>
  privateInstance.put<ITransactionChangeStatusValues, AxiosResponse<boolean>>(API_CHANGE_TRANSACTION_STATUS, values)

export const getSummaryWithdrawal = () => privateInstance.get<any, AxiosResponse<IWithdrawalGetSummaryItem[]>>(API_GET_SUMMARY_WITHDRAWAL)

export const getDealPayments = (params: IGetDealPaymentsParams) =>
  privateInstance.get<IGetDealPaymentsParams, AxiosResponse<IDealPayment[]>>(API_GET_DEAL_PAYMENTS, {
    params: params,
  })

export const createDealPayment = (params: IDealPaymentParams) =>
  privateInstance.post<IDealPaymentParams, AxiosResponse<boolean>>(API_CREATE_DEAL_PAYMENT, params)

export const editTransaction = (params: IEditTransactionParams) =>
  privateInstance.put<IEditTransactionParams, AxiosResponse<boolean>>(API_EDIT_TRANSACTION, params)

export const applyCommission = (id: string | number) =>
  privateInstance.post<typeof id, AxiosResponse<boolean>>(
    generatePath(API_APPLY_COMMISSION, {
      id: id,
    }),
  )

export const applyPsf = (id: string | number) =>
  privateInstance.post<typeof id, AxiosResponse<boolean>>(
    generatePath(API_APPLY_PSF, {
      id: id,
    }),
  )
