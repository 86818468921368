export enum PaymentFrequencyEnum {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Monthly = 'Monthly',
}

export type IPaymentFrequency = 'Daily' | 'Weekly' | 'Monthly' | 'Biweekly' | PaymentFrequencyEnum

export const paymentFrequencyInterval = {
  [PaymentFrequencyEnum.Daily]: 1,
  [PaymentFrequencyEnum.Weekly]: 5,
  [PaymentFrequencyEnum.Biweekly]: 10,
  [PaymentFrequencyEnum.Monthly]: 20,
}

export const paymentFrequencyCalendarDays = {
  [PaymentFrequencyEnum.Daily]: 1,
  [PaymentFrequencyEnum.Weekly]: 7,
  [PaymentFrequencyEnum.Biweekly]: 14,
  [PaymentFrequencyEnum.Monthly]: 30,
}
