import React, { useContext, useMemo } from 'react'
import { Box, Button, CircularProgress, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import SelectComponent from 'UI/Select'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useMutation, useQuery } from 'react-query'
import { NEW_DEAL_URL } from 'constants/routes'
import { useSnackbar } from 'notistack'
import { createBaseDeal, editBaseDeal, getDeal } from 'services/deals'
import { IDealBaseValues, IInhouseDealResponse } from 'typescript/interfaces/deals'
import { useFormik } from 'formik'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { schemaClientDeal, schemaOuthouseDeal } from 'components/ClientDealForm/schema'

import RequiredOption from 'UI/Select/RequiredOption'
import { CompanyType } from 'typescript/interfaces/companies'
import { getClientBankAccounts } from 'services/clients'
import { UserContext } from 'contexts/userContext'
import { useDealInformationFormStyles } from 'components/InhouseDealForm/styles'

interface Props {
  changeStepsAvailable: (steps: number[]) => void
  changeTabsCallBack?: (data: IInhouseDealResponse) => void
}

const ClientDealInformationForm = ({ changeStepsAvailable, changeTabsCallBack }: Props) => {
  const { state } = useContext(UserContext)
  const s = useDealInformationFormStyles()
  const { push, goBack } = useHistory()
  const snack = useSnackbar()
  const { id } = useParams<{ id: string; view: string }>()

  const { isLoading, data } = useQuery(['inhouse-deal'], () => getDeal(id), {
    onSuccess(res) {
      const {
        data: { id: dealId, advanceAmount, term, bankAccount },
      } = res
      setValues({
        id: dealId,
        advanceAmount: advanceAmount,
        term,
        type: 'InHouse',
        bankAccountId: bankAccount.id,
      })
      changeTabsCallBack && changeTabsCallBack(res.data)
    },
    onError() {
      snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
    cacheTime: 0,
    enabled: id,
  })

  const isDisabledToEdit = data?.data.status === 'Declined' || data?.data.status === 'Approved'

  const [mutateCreateDeal, { isLoading: isCreateLoading }] = useMutation(createBaseDeal, {
    onSuccess(res) {
      changeStepsAvailable([0, 1, 2])
      push(
        generatePath(NEW_DEAL_URL, {
          id: res.data.id,
          view: 'edit',
          type: 'client-deal',
          step: 2,
        }),
      )
      snack.enqueueSnackbar('Successfully created deal')
    },
    onError() {
      snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [mutateUpdateDeal, { isLoading: isUpdateLoading }] = useMutation(editBaseDeal, {
    onSuccess(res) {
      push(
        generatePath(NEW_DEAL_URL, {
          id: id,
          view: 'edit',
          type: 'client-deal',
          step: 2,
        }),
      )
      snack.enqueueSnackbar('Successfully updated deal')
    },
    onError() {
      snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const schema = useMemo(() => {
    if (data && data.data.type === CompanyType.InHouse && !isLoading) return schemaClientDeal
    else if (!data && !isLoading) return schemaClientDeal
    return schemaOuthouseDeal
  }, [data, isLoading])

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setValues } = useFormik<IDealBaseValues>({
    validationSchema: schema,
    initialValues: {
      id: undefined,
      advanceAmount: undefined,
      term: undefined,
      type: 'InHouse',
      bankAccountId: '',
    },
    enableReinitialize: false,
    onSubmit: (formValues) => {
      if (id) {
        mutateUpdateDeal(formValues)
      } else {
        mutateCreateDeal(formValues)
      }
    },
  })

  const { data: dataBankAccounts } = useQuery(
    ['client-bank-accounts', state.client?.id],
    () =>
      getClientBankAccounts({
        clientId: state.client ? state.client.id : 0,
      }),
    {
      cacheTime: 0,
      retry: false,
      enabled: state.client,
    },
  )

  if (isLoading) {
    return (
      <Box display="flex" height="400px" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box display="flex" component="form" onSubmit={(e: any) => handleSubmit(e)} className={s.formContainer}>
      <Paper className={s.paper}>
        <Typography className={s.heading}>Deal Information</Typography>

        <Grid container spacing={8}>
          <Grid item xs={12}>
            <TextField
              disabled={isDisabledToEdit}
              variant="outlined"
              fullWidth
              name="advanceAmount"
              onChange={(e) => {
                handleChange(e)
                if (+e.target.value <= 50000) {
                  setFieldValue('uccUploaded', true)
                }
                if (+e.target.value >= 20000) {
                  setFieldValue('monthlyCrmFee', 299)
                }
              }}
              value={values.advanceAmount}
              error={Boolean(touched.advanceAmount && errors.advanceAmount)}
              helperText={touched.advanceAmount && errors.advanceAmount}
              label={<RequiredOption label="Advance Amount" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={isDisabledToEdit}
              variant="outlined"
              fullWidth
              name="term"
              onChange={handleChange}
              value={values.term}
              error={Boolean(touched.term && errors.term)}
              helperText={touched.term && errors.term}
              label={<RequiredOption label="Term" />}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={s.paper}>
        {((data && data.data.type === CompanyType.InHouse) || (!data && !isLoading)) && (
          <Box>
            <Typography className={s.heading}>Business Banking Info</Typography>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <SelectComponent
                  disabled={isDisabledToEdit}
                  name="bankAccountId"
                  label={<RequiredOption label="Bank Account" />}
                  onChange={handleChange}
                  labelProps={{ className: s.selectLabel }}
                  fullWidth
                  error={Boolean(touched.bankAccountId && errors.bankAccountId)}
                  helperText={touched.bankAccountId && errors.bankAccountId}
                  value={values.bankAccountId}
                >
                  {dataBankAccounts && dataBankAccounts.data.length > 0 ? (
                    dataBankAccounts.data.map((ba) => (
                      <MenuItem key={ba.bankAccount.id} value={ba.bankAccount.id}>
                        {ba.bankAccount.bankName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled value="">
                      {dataBankAccounts ? 'No bank accounts' : 'Choose Client'}
                    </MenuItem>
                  )}
                </SelectComponent>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mt="1rem">
          <Button variant="contained" onClick={goBack} startIcon={<ArrowLeftIcon className={s.icon} />} className={s.button}>
            Back
          </Button>
          <Button disabled={isCreateLoading || isUpdateLoading || isDisabledToEdit} type="submit" color="primary" variant="contained">
            Complete
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default ClientDealInformationForm
