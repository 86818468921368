import { fade } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useLastDealsSecondViewStyle = makeStyles((theme) => ({
  wrapperTable: {
    marginLeft: '-3.25rem',
    marginRight: '-3.25rem',
    marginTop: '1.5rem',
    '@media screen and (min-width: 1280px) and (max-width: 1600px)': {
      marginLeft: '-1.5rem',
      marginRight: '-1.5rem',
    },
  },
  headerCell: {
    backgroundColor: theme.palette.common.white,
  },
  accentCell: {
    color: theme.palette.primary.light,
  },
  blueCell: {
    color: theme.palette.secondary.main,
  },
  paperTable: {
    borderRadius: 0,
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  tableContainer: {
    minHeight: 400,
  },
  roundBorder: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '6px',
    padding: '1.5rem 1rem',
  },
}))

export const useTableFooter = makeStyles((theme) => ({
  cellStyle: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    verticalAlign: 'bottom',
    position: 'sticky',
    bottom: '0',
    backgroundColor: theme.palette.common.white,
    padding: '0.875rem 0.875rem',
    // paddingTop: 'calc(1.23rem + 1px)',
    height: '5rem',
    // height: 67,
    borderTop: `1px solid ${theme.palette.divider}`,
    '&$footerTableCell': {
      backgroundColor: '#e7f2e6',
      '& p': {
        whiteSpace: 'nowrap',
      },
    },
    '&$narrow': {
      height: '3.5rem',
    },
    '& .MuiTypography-caption': {
      wordBreak: 'normal',
    },
  },
  footerTableCell: {},
  narrow: {},
}))

export const useDetailedChartStyles = makeStyles((theme) => ({
  navButton: {
    '& svg': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  title: {
    fontSize: '1.875rem',
  },
  value: {
    fontSize: '3rem',
  },
  btn: {
    fontSize: '1rem',
    height: 30,
  },
}))

export const useAmountBlockStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
  },
  value: {
    color: theme.palette.text.hint,
    fontWeight: 600,
  },
}))

export const useRTRSummaryStyles = makeStyles((theme) => ({
  rtrSummaryContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  goodRtr: {
    borderBottom: `1px solid ${fade(theme.palette.primary.main, 0.25)}`,
  },
  typography: {
    fontWeight: 600,
  },
  rtrBlock: {
    position: 'relative',
    border: `2px solid ${fade(theme.palette.primary.main, 0.25)}`,
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    background: '#E6F6F2',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'table',
      width: 0,
      height: 0,
      left: 'calc(50% - 10px)',
      bottom: -22,
      borderLeft: `20px solid transparent`,
      borderRight: `20px solid transparent`,
      borderTop: `20px solid ${fade(theme.palette.primary.main, 0.25)}`,
      borderRadius: 3,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'table',
      width: 0,
      left: 'calc(50% - 10px)',
      zIndex: 1,
      height: 0,
      bottom: -19,
      borderLeft: `20px solid transparent`,
      borderRight: `20px solid transparent`,
      borderTop: `20px solid #E6F6F2`,
      borderRadius: 3,
    },
  },
}))
