import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from 'UI/Checkbox'
import DatePicker from 'UI/DatePicker/DatePicker'
import RepAutoComplete from 'components/RepAutoComplete'
import Button from 'UI/Button/ButtonWithPreloader'
import Grid from '@material-ui/core/Grid'
import InfoBlock from 'components/RepPerformance/InfoBlock'
import { convertToPriceFormat, formatNegativePrice, roundNumber } from 'utils/formatters'
import { TableLastDealsSecondView, useFullDeals } from 'hooks/useFullDeals'
import { usePaginationList } from 'hooks/usePaginationList'
import { IDealsListItem } from 'typescript/interfaces/deals'
import { DealsStatus, SortFieldsDeal } from 'typescript/enums/deals'
import { useMutation } from 'react-query'
import { getDealList } from 'services/deals'
import EquityChartComponent from 'components/RepPerformance/Equity'
import InhouseVsOuthouseChart from 'components/RepPerformance/InhouseVsOuthouse'
import AllIsoUsageChart from 'components/RepPerformance/AllIsoUsage'
import InhouseFundedPerMonth from 'components/RepPerformance/InhouseFundedPerMonth'
import StatusesChart from 'components/RepPerformance/Statuses'
import { getRepPerformance } from 'services/rep'
import { useFormik } from 'formik'
import { schemaRepPerformance } from 'containers/RepPerformance/schema'
import { IEquityData, IFundedPerMonthData, IRepPerformanceEntity, IRepPerformanceParamsFormik } from 'typescript/interfaces/rep'
import { useSnackbar } from 'notistack'
import { endOfMonth, startOfMonth } from 'date-fns'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { UserContext } from 'contexts/userContext'

const RepPerformanceContainer: FC = () => {
  const { state: user } = useContext(UserContext)
  const additionalStyle = useAdditionalMaterialStyle()
  const { enqueueSnackbar } = useSnackbar()
  const [repPerformance, setRepPerformance] = useState<IRepPerformanceEntity[] | undefined>(undefined)
  const { columns } = useFullDeals({ view: 'funded' })
  const { setPagination, handleFetchMore, handleSort, pagination } = usePaginationList<IDealsListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsDeal.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getDealListMut, { isLoading }] = useMutation(getDealList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [getPerformanceMut, { isLoading: isLoadingPerformance }] = useMutation(getRepPerformance, {
    onSuccess: (data) => {
      setRepPerformance(data.data)
    },
  })

  useEffect(() => {
    if (pagination.RepId && pagination.FromDate && pagination.ToDate)
      getPerformanceMut({
        RepId: pagination.RepId,
        FromDate: pagination.FromDate,
        ToDate: pagination.ToDate,
      })
  }, [pagination.RepId, pagination.ShowDeals, pagination.FromDate, pagination.ToDate])

  useEffect(() => {
    if (pagination.ShowDeals && pagination.RepId && pagination.FromDate && pagination.ToDate)
      getDealListMut({
        PageSize: 20,
        PageIndex: pagination.page,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
        RepresentativeId: pagination.RepId,
        Statuses: [DealsStatus.approved, DealsStatus.outhouseApproved],
        FromDate: new Date(pagination.FromDate).toISOString(),
        ToDate: new Date(pagination.ToDate).toISOString(),
      })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.RepId, pagination.ShowDeals, pagination.FromDate, pagination.ToDate])

  useEffect(() => {
    if (repPerformance && repPerformance.length === 0) enqueueSnackbar('No report')
  }, [repPerformance])

  const isRepUser = user.roles.isRep && !!user?.rep?.id;
  const initialRepId = isRepUser ? user.rep!.id.toString() : '';

  const { values, errors, setFieldValue, handleChange, touched, handleSubmit } = useFormik<IRepPerformanceParamsFormik>({
    initialValues: {
      RepId: initialRepId,
      ShowDeals: false,
      FromDate: null,
      ToDate: null,
    },
    validationSchema: schemaRepPerformance,
    onSubmit: (valForms) => {
      setPagination((prevState) => {
        return JSON.stringify({
          RepId: prevState.RepId,
          ShowDeals: prevState.ShowDeals,
          FromDate: prevState.FromDate,
          ToDate: prevState.ToDate,
        }) !== JSON.stringify(valForms)
          ? {
              ...prevState,
              page: 0,
              hasMore: true,
              data: [],
              order: SortFieldsDeal.id,
              sortOrder: 'DESC',
              ...valForms,
            }
          : prevState
      })
    },
  })

  const lastPerformance = useMemo(() => {
    return repPerformance && repPerformance.length > 0 ? repPerformance[repPerformance.length - 1] : undefined
  }, [repPerformance])

  const dataFundedPerMonth: IFundedPerMonthData[] = useMemo(() => {
    return repPerformance
      ? repPerformance.map((i) => ({
          reportDate: i.reportDate,
          fundedPerMonthIH: i.fundedPerMonthIH,
        }))
      : []
  }, [repPerformance])

  const dataEquity: IEquityData[] = useMemo(() => {
    return repPerformance
      ? repPerformance.map((i) => ({
          reportDate: i.reportDate,
          pnlTotal: i.totalPnl,
        }))
      : []
  }, [repPerformance])

  return (
    <>
      <Paper className={additionalStyle.wrapper} elevation={0}>
        <Box p={12}>
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="flex-start">
              <Box>
                <Typography variant="h2" color="textSecondary">
                  Rep Performance Report
                </Typography>
              </Box>
              <Box mr="auto" ml="auto">
                <FormControlLabel
                  control={<Checkbox checked={values.ShowDeals} color="primary" name="ShowDeals" onChange={handleChange} />}
                  label={<Typography color="textSecondary">Show deals</Typography>}
                />
              </Box>
              <Box display="flex" alignItems="flex-start">
                <Box mt="1rem">
                  <Typography variant="h4" color="textSecondary">
                    Date:{' '}
                  </Typography>
                </Box>
                <Box ml="1rem">
                  <DatePicker
                    format="MMMM yyyy"
                    views={['year', 'month']}
                    value={values.FromDate}
                    name="from"
                    error={Boolean(touched.FromDate && errors.FromDate)}
                    helperText={touched.FromDate && errors.FromDate}
                    onChange={(date) => {
                      setFieldValue('FromDate', date ? startOfMonth(new Date(date.toISOString())) : null)
                    }}
                    label="From"
                  />
                </Box>
                <Box ml="1rem">
                  <DatePicker
                    format="MMMM yyyy"
                    views={['year', 'month']}
                    value={values.ToDate}
                    name="ToDate"
                    error={Boolean(touched.ToDate && errors.ToDate)}
                    helperText={touched.ToDate && errors.ToDate}
                    onChange={(date) => {
                      setFieldValue('ToDate', date ? endOfMonth(new Date(date.toISOString())) : null)
                    }}
                    label="To"
                  />
                </Box>
                {!isRepUser && <Box ml="1rem" minWidth={200}>
                  <RepAutoComplete
                    repId={values.RepId}
                    error={Boolean(touched.RepId && errors.RepId)}
                    helperText={touched.RepId && errors.RepId}
                    onChange={(e, val) => {
                      if (val) setFieldValue('RepId', val.id)
                      else setFieldValue('RepId', '')
                    }}
                  />
                </Box>}
                <Box ml="1rem">
                  <Button type="submit" color="primary" variant="contained">
                    View report
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
          {lastPerformance && (
            <Box mt={8}>
              <Grid container spacing={2}>
                <Grid spacing={2} container item xs={10}>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="Deals:"
                      isLoading={isLoadingPerformance}
                      value={`${convertToPriceFormat(lastPerformance.totalAmount)}(${lastPerformance.dealsCount})`}
                      inhouse={`${convertToPriceFormat(lastPerformance.totalAmountIH)}(${lastPerformance.dealsCountIH})`}
                      outhouse={`${convertToPriceFormat(lastPerformance.totalAmountOH)}(${lastPerformance.dealsCountOH})`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="Commissions:"
                      isLoading={isLoadingPerformance}
                      value={convertToPriceFormat(lastPerformance.totalCommission)}
                      inhouse={convertToPriceFormat(lastPerformance.totalCommissionIH)}
                      outhouse={convertToPriceFormat(lastPerformance.totalCommissionOH)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="PSF:"
                      isLoading={isLoadingPerformance}
                      value={convertToPriceFormat(lastPerformance.totalPsf)}
                      inhouse={convertToPriceFormat(lastPerformance.totalPsfIH)}
                      outhouse={convertToPriceFormat(lastPerformance.totalPsfOH)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="Deals PNL:"
                      value={formatNegativePrice(lastPerformance.totalPnl, convertToPriceFormat)}
                      inhouse={formatNegativePrice(lastPerformance.totalPnlIH, convertToPriceFormat)}
                      outhouse={formatNegativePrice(lastPerformance.totalPnlOH, convertToPriceFormat)}
                      isLoading={isLoadingPerformance}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="AVG Deal:"
                      isLoading={isLoadingPerformance}
                      value={convertToPriceFormat(lastPerformance.avgAmount)}
                      inhouse={convertToPriceFormat(lastPerformance.avgAmountIH)}
                      outhouse={convertToPriceFormat(lastPerformance.avgAmountOH)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="AVG Commissions:"
                      isLoading={isLoadingPerformance}
                      value={convertToPriceFormat(lastPerformance.avgCommission)}
                      inhouse={convertToPriceFormat(lastPerformance.avgCommissionIH)}
                      outhouse={convertToPriceFormat(lastPerformance.avgCommissionOH)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="AVG PSF:"
                      isLoading={isLoadingPerformance}
                      value={convertToPriceFormat(lastPerformance.avgPsf)}
                      inhouse={convertToPriceFormat(lastPerformance.avgPsfIH)}
                      outhouse={convertToPriceFormat(lastPerformance.avgPsfOH)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InfoBlock
                      title="AVG Deals PNL:"
                      value={formatNegativePrice(lastPerformance.avgPnl, convertToPriceFormat)}
                      inhouse={formatNegativePrice(lastPerformance.avgPnlIH, convertToPriceFormat)}
                      outhouse={formatNegativePrice(lastPerformance.avgPnlOH, convertToPriceFormat)}
                      isLoading={isLoadingPerformance}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <InfoBlock
                    title="Default Ratio:"
                    value={`${roundNumber(lastPerformance.defaultRatio * 100)}%`}
                    inhouse={`${roundNumber(lastPerformance.defaultRatioIH * 100)}%`}
                    outhouse={`${roundNumber(lastPerformance.defaultRatioOH * 100)}%`}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
      {lastPerformance && (
        <Box className={additionalStyle.wrapper} mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0}>
                <EquityChartComponent data={dataEquity} />
              </Paper>
            </Grid>
            {/*<Grid item xs={4}>*/}
            {/*  <Paper elevation={0}>*/}
            {/*    <PnlVsTermChart/>*/}
            {/*  </Paper>*/}
            {/*</Grid>*/}
            <Grid item xs={4}>
              <Paper elevation={0}>
                <StatusesChart performanceDealStatuses={lastPerformance.repPerformanceDealPaidStatuses} />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0}>
                <InhouseFundedPerMonth data={dataFundedPerMonth} />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0}>
                <InhouseVsOuthouseChart inhouse={lastPerformance.dealsCountIH} outhouse={lastPerformance.dealsCountOH} />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0}>
                <AllIsoUsageChart data={lastPerformance.repPerformanceDealIsos} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      {pagination.ShowDeals && (
        <Box className={additionalStyle.wrapper} mt={3}>
          <Paper elevation={0}>
            <TableLastDealsSecondView
              handleSortChange={handleSort}
              stickyHeader
              columns={columns}
              loading={isLoading}
              handleGetMore={handleFetchMore}
              data={pagination.data}
              hasMore={pagination.hasMore}
            />
          </Paper>
        </Box>
      )}
    </>
  )
}

export default RepPerformanceContainer
