import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import Decimal from 'decimal.js'
import { useFormik } from 'formik'
import SwitchExtended from 'UI/Switch/SwitchExtended';
import SplitIsoCommissionInput from './SplitIsoCommissionInput';

interface Props {
  initialValues: { [key: number]: Decimal }
  name: string
  selectedIsos: Array<{ id: number; name: string }>
  isoFeeAmount: number | undefined
  disabled: boolean | undefined
  propsSetFieldValue: (name: string, data: any[]) => void
  handleClosePopover: () => void
  classes: any
}

interface ISplitIsoCommissionForm {
  [key: number]: IIsoCommision
}

interface IIsoSplitCommisionValidation{
  validate: (data: Array<IIsoCommision>) => {},
  error: string
}

export interface IIsoCommision {
  percentage: string,
  amount: string
}

export enum SplitIsoCommisionCalcultationMode {
  Percentage,
  Amount
}

const validations: IIsoSplitCommisionValidation[] = [
  {
    validate: (data: Array<IIsoCommision>) => {
      const sum = data.reduce((acc, el) => {
        return acc.plus(new Decimal(el.percentage));
      }, new Decimal(0));
        
      return !sum.greaterThan(100);
    },
    error: 'Total split value cannot be higher than Iso Fee.'
  },
  {
    validate: (data: Array<IIsoCommision>) => {
      const sum = data.reduce((acc, el) => {
        return acc.plus(new Decimal(el.percentage));
      }, new Decimal(0));
        
      return !sum.lessThan(100);
    },
    error: 'Total split value cannot be lower than Iso Fee.'
  },
];

export default function SplitIsoCommissionForm({
  initialValues,
  selectedIsos,
  isoFeeAmount,
  propsSetFieldValue,
  handleClosePopover,
  name,
  disabled,
  ...props
}: Props): ReactElement {
  const [error, setError] = useState<string | null>(null);
  const [mode, setMode] = useState<SplitIsoCommisionCalcultationMode>(SplitIsoCommisionCalcultationMode.Amount);
  let initialFormValue : ISplitIsoCommissionForm = {};

  const calculateComissionAmount = useCallback((percentage: Decimal) : Decimal => {
    const calculatedAmount = new Decimal(isoFeeAmount ?? 0).times(percentage).div(100).toDecimalPlaces(4);
    
    return calculatedAmount;
  }, [isoFeeAmount]);

  for (const [key, value] of Object.entries(initialValues)) {
      const numberKey = Number(key);
      const percentage = Decimal(value);

      initialFormValue = {
        ...initialFormValue,
        [numberKey]: {
          percentage: percentage.toString(),
          amount: calculateComissionAmount(percentage).toString()
        }
      }
  }

  const { values, handleSubmit, setFieldValue, setValues } = useFormik({
    initialValues: initialFormValue,
    enableReinitialize: false,
    onSubmit: (formValues) => {
      const commisions: Array<IIsoCommision> = Object.values(formValues);
      
      for (const validation of validations) {
        if (!validation.validate(commisions)) {
          return setError(validation.error);
        }
      }

      const result: Array<{ id: number, value: Decimal }> = [];
      for (const [key, value] of Object.entries(formValues)) {
        const numberKey = Number(key);
        const numberPercentage = Decimal(value.percentage);
  
        result.push({ id: numberKey, value: numberPercentage })
      }

      propsSetFieldValue(name, result)
      handleClosePopover()
    },
  })

  useEffect(() => {
    if (mode !== SplitIsoCommisionCalcultationMode.Percentage) {
      return;
    }

    let newFormValue : ISplitIsoCommissionForm = {};

    for (const [key, value] of Object.entries(values)) {
      const numberKey = Number(key);
      const percentage = Decimal(value.percentage);

      const hasMoreThanTwoDigitsAfterComa = percentage.times(100).mod(1).greaterThan(0);

      if (!hasMoreThanTwoDigitsAfterComa) {
        newFormValue = {
          ...newFormValue,
          [numberKey]: value
        }
        // eslint-disable-next-line no-continue
        continue;
      }

      const newPercentage = percentage.toDecimalPlaces(2);

      newFormValue = {
        ...newFormValue,
        [numberKey]: {
          percentage: newPercentage.toString(),
          amount: calculateComissionAmount(newPercentage).toString()
        }
      }
    }

    setValues(newFormValue);
  }, [mode]);

  return (
    <form onSubmit={handleSubmit}>
      {mode === SplitIsoCommisionCalcultationMode.Amount
        ? <SwitchExtended
          key={'amount'}
          variants={[
            { title: 'Amount', value: 'amount' },
            { title: 'Percentage', value: 'percentage' },
          ]}
          value="amount"
        onClick={() => {
          setMode(SplitIsoCommisionCalcultationMode.Percentage);
        }
        }
      />
        : <SwitchExtended
          key={'percentage'}
          variants={[
            { title: 'Amount', value: 'amount' },
            { title: 'Percentage', value: 'percentage' },
          ]}
          value="percentage"
        onClick={() => {
          setMode(SplitIsoCommisionCalcultationMode.Amount);
        }
        }
      />
      }
      <Grid container style={{ marginTop: '2rem' }}>
        {selectedIsos.map(({ id, name: isoName }) => {
                const currentCommision = values[id];

                return (
                  <SplitIsoCommissionInput
                    key={id}
                    {...props}
                    commision={currentCommision}
                    disabled={disabled}
                    mode={mode}
                    isoName={isoName}
                    isoId={id}
                    isoFeeAmount={isoFeeAmount}
                    onChange={(changedId, commision) => setFieldValue(changedId.toString(), commision)}/>)
                })}
      </Grid>
      
      {error && (
        <Typography gutterBottom color="error">
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        color="primary"
        variant="contained"
        fullWidth>
        Split
      </Button>
    </form>
  )
}
