/* eslint-disable newline-per-chained-call */
import {
  COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT,
  MAX_VALUE_IS,
  MIN_VALUE_IS,
  MIN_VALUE_IS_ONE,
  NUMBERS_ONLY,
  NUMBER_CANNOT_BE_NEGATIVE,
  REQUIRED_FIELD,
  MUST_BE_INTEGER,
  ONLY_DIGITS,
  FLEX_NEED_TO_BE_LOWER_THAN_ADVANCED_AMOUNT,
  INCORRECT_COMMISSION_SPLIT_TOTAL,
} from 'constants/errors'
import { paymentFrequency } from 'constants/paymentFrequency'
import * as yup from 'yup'
import { integerReg } from 'constants/regExp'
import { IDocuments, IExtendType, IFlexDisbursement, IUploadedDocuments, extendTypes } from 'typescript/interfaces/deals'
import { IBankAccountClient } from 'typescript/interfaces/clients'
import { monthNames } from 'constants/misc'
import { getBankStatementFileName } from 'utils/bankStatementHelpers'
import Decimal from 'decimal.js'

export const schemaInHouseDeal = yup.object().shape({
  clientId: yup.number().typeError(NUMBERS_ONLY).required(REQUIRED_FIELD),
  companyId: yup.string().required(REQUIRED_FIELD),
  isoId: yup.number().typeError(NUMBERS_ONLY),
  paymentFrequency: yup.string().oneOf(paymentFrequency).required(REQUIRED_FIELD),
  disbursementFrequency: yup.mixed().when('extendType', {
    is: <IExtendType>'Reverse',
    then: yup.string().oneOf(paymentFrequency).required(REQUIRED_FIELD),
    otherwise: yup.string().nullable(),
  }),
  advanceAmount: yup.string().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY).matches(integerReg, ONLY_DIGITS),
  commissionToRep: yup
    .string()
    .nullable()
    .when('representativeIds', {
      is: (val) => !!val,
      then: yup.string().required(REQUIRED_FIELD),
    })
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function (value) {
      if (!this.parent.representativeIds) return true

      const rFee = value || 0
      const amount = this.parent.advanceAmount || 0

      return amount * 0.15 >= Number(rFee)
    })
    .matches(integerReg, ONLY_DIGITS)
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  isoFee: yup
    .string()
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function () {
      if (!this.parent.isoIds?.length) return true

      const іFee = this.parent.isoFee || 0
      const amount = this.parent.advanceAmount || 0

      return amount * 0.15 >= іFee
    })
    .matches(integerReg, ONLY_DIGITS)
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  isoSplitCommission: yup.array().test('isoSplitCommission', INCORRECT_COMMISSION_SPLIT_TOTAL, function (value) {
    if (!value?.length) return true
    return (value as Array<{ value: number }>).map((x) => new Decimal(x.value))
      .reduce((a, b) => a.plus(b), new Decimal(0))
      .equals(100);
  }),
  commissionSplit: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  psfFee: yup
    .string()
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function () {
      const pFee = this.parent.psfFee || 0
      const amount = this.parent.advanceAmount || 0

      return amount * 0.15 >= pFee
    })
    .matches(integerReg, ONLY_DIGITS)
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  syndicationFee: yup.string().matches(integerReg, ONLY_DIGITS).typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  payOffAmount: yup.string().matches(integerReg, ONLY_DIGITS).typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  comments: yup.string(),
  term: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(integerReg, MUST_BE_INTEGER)
    .test('integer', MIN_VALUE_IS_ONE, function (val) {
      return parseInt(val || '') >= 1
    }),
  reverseTerm: yup.mixed().when('extendType', {
    is: <IExtendType>'Reverse',
    then: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(integerReg, MUST_BE_INTEGER)
      .test('integer', MIN_VALUE_IS_ONE, function (val) {
        return parseInt(val || '') >= 1
      }),
    otherwise: yup.string().nullable(),
  }),
  factor: yup
    .number()
    .typeError(NUMBERS_ONLY)
    .min(1, MIN_VALUE_IS.replace(':value', '1'))
    .max(2, MAX_VALUE_IS.replace(':value', '2'))

    .required(REQUIRED_FIELD),
  representativeIds: yup.number().typeError(NUMBERS_ONLY),
  bankAccountId: yup.string().required(REQUIRED_FIELD),
  flexAmount: yup.mixed().when('isFlexDeal', {
    is: true,
    then: yup
      .string()
      .required(REQUIRED_FIELD)
      .typeError(NUMBERS_ONLY)
      .matches(integerReg, ONLY_DIGITS)
      .test('flexAmount', FLEX_NEED_TO_BE_LOWER_THAN_ADVANCED_AMOUNT, function (val) {
        const totalDisbursement = (this.parent.flexSchedule as IFlexDisbursement[] | undefined)
          ?.map(({ amount }) => Number(amount) || 0)
          ?.reduce((a, b) => a + b, 0)
        const totalFlex = (Number(val) || 0) + (totalDisbursement || 0)
        return totalFlex <= (Number(this.parent.advanceAmount) || 0)
      }),
    otherwise: yup.mixed(),
  }),
  extendType: yup.mixed().when('isFlexDeal', {
    is: true,
    then: yup.string().oneOf(extendTypes).required(REQUIRED_FIELD),
    otherwise: yup.string().nullable(),
  }),
  monthlyRevenue: yup.number().typeError(NUMBERS_ONLY).required(REQUIRED_FIELD).min(0, NUMBER_CANNOT_BE_NEGATIVE).integer(),
  specifiedPercentage: yup
    .number()
    .typeError(NUMBERS_ONLY)
    .required(REQUIRED_FIELD)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE)
    .max(100, MAX_VALUE_IS.replace(':value', '100')),
  clientState: yup.string(),
  apr: yup
    .number()
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE)
    .when('clientState', (state, schema) => (['NY', 'CA'].includes(state) ? schema.required(REQUIRED_FIELD) : schema)),
})

interface IDocumentsValidationSchemaParams {
  bankAccounts?: IBankAccountClient[]
  dealCreationDate?: Date
  clientState?: string
  isRefiDeal: boolean
  bankStatementsRequired: boolean
}

export const getDocumentsValidationSchema = ({
  bankAccounts,
  dealCreationDate,
  clientState,
  isRefiDeal,
  bankStatementsRequired,
}: IDocumentsValidationSchemaParams) => {
  return yup.object().shape({
    documents: yup.object().shape({
      VoidedCheck: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
        const opts = this.options as any
        const { value: grandparent } = opts.from[1]
        return this.parent?.VoidedCheck.length > 0 || grandparent?.uploadedDocuments.VoidedCheck.length > 0
      }),
      Application: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
        const opts = this.options as any
        const { value: grandparent } = opts.from[1]
        return this.parent.Application.length > 0 || grandparent?.uploadedDocuments?.Application.length > 0
      }),
      DriverLicense: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
        const opts = this.options as any
        const { value: grandparent } = opts.from[1]
        return this.parent.DriverLicense.length > 0 || grandparent?.uploadedDocuments?.DriverLicense.length > 0
      }),
      BankStatements: yup.array().test('required', REQUIRED_FIELD, function () {
        if (!bankAccounts || !dealCreationDate || !clientState || isRefiDeal || !bankStatementsRequired) return true

        const year = dealCreationDate.getUTCFullYear()
        const months = getRequiredMonths(['NY', 'CA'].includes(clientState) ? 5 : 4, dealCreationDate)
        const requiredNames = bankAccounts.flatMap(({ bankAccount }) => months.map((month) => getBankStatementFileName(bankAccount, month, year)))

        const files = <IDocuments>this.parent
        const uploadedFiles: IUploadedDocuments = (<any>this.options).from[1]?.value?.uploadedDocuments
        const existingNames = [...files.BankStatements.map((x) => x.name), ...uploadedFiles.BankStatements.map((x) => x.description)]

        const errors = requiredNames
          .filter((x) => !existingNames.some((e) => e.startsWith(x)))
          .map((x) => `${x} is required`)
          .join('; ')

        return errors ? this.createError({ message: errors }) : true
      }),
    }),
    uploadedDocuments: yup.object().shape({}),
  })
}

export const merchantClientsValidationSchema = yup.object({
  clients: yup.array().of(
    yup.object().shape({
      amountOwing: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
    }),
  ),
})

const getRequiredMonths = (quantity: number, dealCreationDate: Date) => {
  const currentMonth = dealCreationDate.getUTCMonth()
  const currentMonth8th = new Date(dealCreationDate.getUTCFullYear(), currentMonth, 8)
  const start = Math.max(currentMonth - quantity + 1, 0)
  const end = dealCreationDate >= currentMonth8th ? currentMonth + 1 : currentMonth

  return monthNames.slice(start, end)
}
