import { BANK_ROUTING_MUST_BE_NINE_SYMBOLS } from 'constants/errors'
import { Role } from 'constants/roles'
import { useQuery } from 'react-query'
import { validateBankRouting } from 'services/banks'
import { IBankInfo } from 'typescript/interfaces/banks'
import * as yup from 'yup'

export const bankRoutingSchema = yup
  .string()
  .matches(/^\d{9}$/, BANK_ROUTING_MUST_BE_NINE_SYMBOLS)
  .required()

const useValidateBankRouting = (bankRouting: string) => {
  const { data, isLoading } = useQuery(['validateBankRouting', bankRouting], () => validateBankRouting(bankRouting), {
    retry: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    enabled: bankRoutingSchema.isValidSync(bankRouting),
  })

  return [data?.data, isLoading] as const
}

export default useValidateBankRouting

export interface IValidateBankRouting {
  bankInfo?: IBankInfo
  bankInfoWire?: IBankInfo
  entityState?: string
}

export const createStateMatchTest = (bankInfoField: keyof Omit<IValidateBankRouting, 'entityState'>, role: Role) =>
  yup.string().test({
    name: 'state-matching',
    message: 'Invalid state match.',
    test: function () {
      const { [bankInfoField]: bankInfo, entityState } = this.parent as IValidateBankRouting
      return entityState && bankInfo?.bankState && bankInfo.bankState !== entityState
        ? this.createError({ message: `The bank routing belongs to ${bankInfo.bankState}, but the client state is ${entityState}` })
        : true
    },
  })

export const createBankMatchTest = () =>
  yup.string().test('bank-matching', 'Both Bank Routings must belong to the same bank.', function () {
    const { bankInfo, bankInfoWire } = this.parent as IValidateBankRouting
    return !bankInfo?.bankName || !bankInfoWire?.bankName || bankInfo.bankName === bankInfoWire.bankName
  })
