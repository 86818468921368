import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'

interface IConfirmationContent {
  handleCancel: () => void
  handleConfirm: () => void
  isLoading?: boolean
  text?: string
  renderedElement?: React.ReactElement,
  confirmText?: string, 
  cancelText?: string
}

const ConfirmationContent: FC<IConfirmationContent> = ({
  handleCancel,
  handleConfirm,
  isLoading,
  text,
  renderedElement,
  confirmText = 'Confirm',
  cancelText = 'Cancel'}) => {
  return (
    <Box>
      <Box>
        <Typography variant="h3" color="textSecondary">
          {text || renderedElement}
        </Typography>
      </Box>
      <Box mt="2rem" display="flex" alignItems="center" justifyContent="flex-end">
        <Button onClick={handleCancel} loading={isLoading} color="primary" variant="text">
          { cancelText }
        </Button>
        <Box ml="2rem">
          <Button onClick={handleConfirm} loading={isLoading} color="primary" variant="contained">
            { confirmText }
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ConfirmationContent
