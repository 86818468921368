import { MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { distributionOptions, IExtendType, IFeeDistributionOption } from "typescript/interfaces/deals";
import ModalComponent from "UI/Modal";
import ConfirmationContent from "UI/Modal/ConfirmationContent";
import SelectComponent, { ISelectComponent } from "UI/Select";

interface Props extends Omit<ISelectComponent, 'onChange'> {
    value?: IFeeDistributionOption
    extendType?: IExtendType
    onChange: (v?: IFeeDistributionOption) => {}
}

const getOptionText = (option: IFeeDistributionOption) : string => {
    if (option === 'AllInOne') {
        return 'Add to the First Disbursement';
    }

    return option;
}

const BankFeeDistributionSelector = ({
    value: initialValue,
    extendType,
    onChange,
    ...props
}: Props) => {
    const [showChangeOptionModal, setShowChangeOptionModal] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<IFeeDistributionOption | undefined>(initialValue);

    const changeOptionModalText = selectedOption === 'Distribute' ? 'distribute the Bank fee' : 'add Bank fee to the first disbursement';

    useEffect(() => {
        if (!extendType && selectedOption) {
            onChange(undefined)
        }
    }, [extendType])

    const handleCancel = () => {
        setShowChangeOptionModal(false);
    }

    const handleConfirm = () => {
        onChange(selectedOption!);
        setShowChangeOptionModal(false);
    }

    const handleOptionSelect = (option: IFeeDistributionOption) => {
        if (!option || option === selectedOption) {
            return;
        }

        setSelectedOption(option);
        setShowChangeOptionModal(true);
    }

    return (
        <>
            <SelectComponent
                {...props}
                value={initialValue ?? (extendType === 'Flex' ? 'Distribute' : '')}>
                {distributionOptions.map((item) => (
                    <MenuItem key={item} value={item} onClick={(e) => { handleOptionSelect(item) }}>
                        {getOptionText(item)}
                    </MenuItem>
                ))}
            </SelectComponent>

            <ModalComponent open={showChangeOptionModal} onClose={handleCancel}>
                <ConfirmationContent
                    handleCancel={handleCancel}
                    handleConfirm={handleConfirm}
                    text={`This cannot be changed after submission, are you sure you want to ${changeOptionModalText}?`}
                    confirmText="Yes"
                    cancelText="No"
                    />
            </ModalComponent>
        </>
    );
}

export default BankFeeDistributionSelector;