import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import { ICreateEditBankAccountClientValues } from 'typescript/interfaces/clients'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField/TextField'
import RequiredOption from 'UI/Select/RequiredOption'
import SelectComponent from 'UI/Select'
import { bankAccountTypes } from 'constants/bankAccountType'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Button from 'UI/Button/ButtonWithPreloader'
import Typography from '@material-ui/core/Typography'
import { schemaClientBankAccount } from 'components/AddClientBankAccount/schema'
import { useSnackbar } from 'notistack'
import useDebouncedValue from 'hooks/Custom/useDebouncedValue'
import useValidateBankRouting, { IValidateBankRouting } from 'hooks/useValidateBankRouting'
import { useQuery } from 'react-query'
import { getClientById } from 'services/clients'
import useStateAbbreviation from 'hooks/useStateAbbreviation'
import TextFieldWithPreloader from 'UI/TextField/TextFieldWithPreloader'
import { InputAdornment } from '@material-ui/core'
import { IBankAccount } from 'typescript/interfaces/companies'

type AddClientBankAccountFormValues = Omit<ICreateEditBankAccountClientValues, 'bankAccount'> & {
  bankAccount: IBankAccount & IValidateBankRouting
}

type InitValues = Omit<ICreateEditBankAccountClientValues, 'clientId'>

export interface IAddClientBankAccountFormProps {
  clientId: string | number
  submit: (values: ICreateEditBankAccountClientValues) => void
  initValues?: InitValues
  cancel: () => void
  isLoading?: boolean
  disableBalance?: boolean
}

const constInitValues: InitValues = {
  isMain: false,
  bankAccount: {
    bankAccountName: '',
    bankAccount: '',
    bankRouting: '',
    bankRoutingWire: '',
    availableBalance: '',
    bankAccountType: '',
    bankName: '',
  },
}

const AddClientBankAccountForm: FC<IAddClientBankAccountFormProps> = ({
  clientId,
  submit,
  initValues = constInitValues,
  cancel,
  isLoading,
  disableBalance,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, values, touched, errors, handleChange, setFieldValue } = useFormik<AddClientBankAccountFormValues>({
    initialValues: {
      clientId: clientId,
      ...initValues,
    },
    validationSchema: schemaClientBankAccount,
    onSubmit: (vals) => {
      if (initValues.isMain && !vals.isMain)
        enqueueSnackbar('At least one bank account should be main. Select another bank account as main if you want to change the main bank account')
      else submit(vals)
    },
  })

  const client = useQuery(['getClientById', clientId], () => getClientById(clientId.toString()))
  const debouncedBankRouting = useDebouncedValue(values.bankAccount.bankRouting, 1000)
  const debouncedBankRoutingWire = useDebouncedValue(values.bankAccount.bankRoutingWire, 1000)
  const [bankInfo, isLoadingBankInfo] = useValidateBankRouting(debouncedBankRouting)
  const [bankInfoWire, isLoadingBankInfoWire] = useValidateBankRouting(debouncedBankRoutingWire)
  const [entityState, isLoadingEntityState] = useStateAbbreviation(client.data?.data.phisicalStateId)
  const isLoadingAny = isLoading || isLoadingBankInfo || isLoadingBankInfoWire || isLoadingEntityState

  useEffect(() => {
    if (entityState) setFieldValue('bankAccount.entityState', entityState)
    if (bankInfo) setFieldValue('bankAccount.bankInfo', bankInfo)
    if (bankInfoWire) setFieldValue('bankAccount.bankInfoWire', bankInfoWire)
  }, [bankInfo, bankInfoWire, entityState])

  return (
    <form onSubmit={handleSubmit}>
      <Box maxWidth="100%" width={400}>
        <Box my="1rem">
          <Typography variant="h2" color="textSecondary">
            Bank Account
          </Typography>
        </Box>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <SelectComponent
              name="bankAccount.bankAccountType"
              onChange={handleChange}
              value={values.bankAccount.bankAccountType}
              error={Boolean(touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType)}
              helperText={touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType}
              fullWidth
              label={<RequiredOption label="Bank Account Type" />}
            >
              {bankAccountTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
          <Grid item xs={12}>
            <TextFieldWithPreloader
              variant="outlined"
              fullWidth
              name="bankAccount.bankRouting"
              onChange={handleChange}
              value={values.bankAccount.bankRouting}
              error={Boolean(touched.bankAccount?.bankRouting && errors.bankAccount?.bankRouting)}
              helperText={touched.bankAccount?.bankRouting && errors.bankAccount?.bankRouting}
              label={<RequiredOption label="ACH Bank Routing #" />}
              isLoading={isLoadingBankInfo || isLoadingEntityState}
              isValid={!errors.bankAccount?.bankRouting && !!bankInfo?.bankName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldWithPreloader
              variant="outlined"
              fullWidth
              name="bankAccount.bankRoutingWire"
              onChange={handleChange}
              value={values.bankAccount.bankRoutingWire}
              error={Boolean(touched.bankAccount?.bankRoutingWire && errors.bankAccount?.bankRoutingWire)}
              helperText={touched.bankAccount?.bankRoutingWire && errors.bankAccount?.bankRoutingWire}
              label={<RequiredOption label="Wire Bank Routing #" />}
              isLoading={isLoadingBankInfoWire || isLoadingEntityState}
              isValid={!errors.bankAccount?.bankRoutingWire && !!bankInfoWire?.bankName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="bankAccount.bankAccount"
              onChange={handleChange}
              value={values.bankAccount.bankAccount}
              error={Boolean(touched.bankAccount?.bankAccount && errors.bankAccount?.bankAccount)}
              helperText={touched.bankAccount?.bankAccount && errors.bankAccount?.bankAccount}
              label={<RequiredOption label="Bank Account #" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="bankAccount.bankName"
              onChange={handleChange}
              value={values.bankAccount.bankName}
              error={Boolean(touched.bankAccount?.bankName && errors.bankAccount?.bankName)}
              helperText={touched.bankAccount?.bankName && errors.bankAccount?.bankName}
              label={<RequiredOption label="Bank Name" />}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={isLoadingAny || !values.bankAccount?.bankInfo?.bankName}
                      size="small"
                      variant="text"
                      style={{ minWidth: 0 }}
                      onClick={() => setFieldValue('bankAccount.bankName', values.bankAccount?.bankInfo?.bankName)}
                    >
                      Autofill
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="bankAccount.bankAccountName"
              onChange={handleChange}
              value={values.bankAccount.bankAccountName}
              error={Boolean(touched.bankAccount?.bankAccountName && errors.bankAccount?.bankAccountName)}
              helperText={touched.bankAccount?.bankAccountName && errors.bankAccount?.bankAccountName}
              label={<RequiredOption label="Bank Account Name" />}
            />
          </Grid>
        </Grid>
        <Box mt="1rem" display="flex" alignItems="center" justifyContent="flex-end">
          <Button loading={isLoading} color="secondary" variant="contained" onClick={() => cancel()}>
            Cancel
          </Button>
          <Box ml="1rem">
            <Button loading={isLoadingAny} color="primary" variant="contained" type="submit">
              Complete
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default AddClientBankAccountForm
