import { useEffect, useRef } from 'react'

const useDebouncedEffect = (callback: () => void, [delay, ...dependencies]: [number, ...any[]]) => {
  const debounceRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current)

    debounceRef.current = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      if (debounceRef.current) clearTimeout(debounceRef.current)
    }
  }, [delay, ...dependencies])
}

export default useDebouncedEffect
