import * as yup from 'yup'
import {
  INVALID_EMAIL,
  INVALID_FEDERAL_TAX_ID,
  INVALID_PHONE_NUMBER,
  INVALID_PRICE_FORMAT,
  REQUIRED_FIELD,
  ZIP_CODE_FORMAT_ERROR,
} from 'constants/errors'
import { integerReg, priceReg, zipCodeReg } from 'constants/regExp'
import { bankRoutingSchema, createBankMatchTest, createStateMatchTest } from 'hooks/useValidateBankRouting'
import { Role } from 'constants/roles'

export const userPageSchema = (isEdit: boolean, role: Role) =>
  yup.object().shape({
    bankName: yup.string().required(REQUIRED_FIELD),
    availableBalance: yup.string().concat(!isEdit ? yup.string().required(REQUIRED_FIELD).matches(priceReg, INVALID_PRICE_FORMAT) : yup.string()),
    bankRouting: yup
      .string()
      .concat(bankRoutingSchema)
      .concat(createStateMatchTest('bankInfo', role))
      .concat(createBankMatchTest())
      .required(REQUIRED_FIELD),
    bankRoutingWire: yup
      .string()
      .concat(bankRoutingSchema)
      .concat(createStateMatchTest('bankInfoWire', role))
      .concat(createBankMatchTest())
      .nullable()
      .notRequired(),
    bankAccount: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(integerReg, 'Bank Account must be numeric')
      .test('isCorrectLength', 'Must be at least 5 characters and no more than 15', function (val) {
        return Boolean(val && val.length >= 5 && val.length <= 15)
      }),
    bankAccountName: yup.string().nullable().required(REQUIRED_FIELD),
  })

export const schemaVendorForm = (isEdit: boolean) =>
  yup.object().shape({
    vendorType: yup.string().required(REQUIRED_FIELD),
    ein: yup.string(),
    email: yup.string().required(REQUIRED_FIELD).email(INVALID_EMAIL),
    nameOnCheck: yup.string(),
    nameOnBankAccount: yup.string(),
    phone: yup
      .string()
      .required(REQUIRED_FIELD)
      .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
    userPage: userPageSchema(isEdit, 'Collector'),
    businessName: yup.string().required(REQUIRED_FIELD),
    city: yup.string().required(REQUIRED_FIELD),
    street: yup.string().required(REQUIRED_FIELD),
    stateId: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    federalTaxId: yup
      .string()
      .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
      .required(REQUIRED_FIELD),
    zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  })
