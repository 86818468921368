import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from 'UI/Button/ButtonWithPreloader'
import { Box, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'
import { initValuesIso } from 'components/ISOForm/constants'
import { schemaIsoForm } from 'components/ISOForm/schema'
import Paper from '@material-ui/core/Paper/Paper'
import Typography from '@material-ui/core/Typography'
import { useHistory, useParams } from 'react-router-dom'
import RequiredOption from 'UI/Select/RequiredOption'
import { IUserPage } from 'typescript/interfaces/users'
import SelectComponent from 'UI/Select'
import { useStates } from 'hooks/useStates'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import useValidateBankRouting, { IValidateBankRouting } from 'hooks/useValidateBankRouting'
import useDebouncedValue from 'hooks/Custom/useDebouncedValue'
import TextFieldWithPreloader from 'UI/TextField/TextFieldWithPreloader'

type IsoFormValues = Omit<IIsoInitialValues, 'userPage'> & {
  userPage: IUserPage & IValidateBankRouting
}

interface IIsoFormProps {
  submitHandler: (values: any) => any
  initialValues?: IIsoInitialValues
  loading?: boolean
  label: string
}

export interface IIsoInitialValues {
  email: string
  firstName: string
  lastName: string
  phone: string
  mobile: string
  fax: string
  nameOnCheck: string
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  stateId: number | ''
  userPage: IUserPage
}

const useStyles = makeStyles(() => ({
  bankInfoContainer: {
    '& .MuiGrid-item': {
      paddingBottom: '2rem',
    },
  },
}))

const ISOForm: FC<IIsoFormProps> = ({ submitHandler, initialValues = initValuesIso, loading = false, label }) => {
  const additionalStyle = useAdditionalMaterialStyle()
  const styles = useStyles()
  const { id } = useParams<{ id: string }>()
  const { goBack } = useHistory()
  const { data: statesData, isLoading: isLoadingStates } = useStates()
  const { handleSubmit, values, handleChange, touched, errors, setFieldValue } = useFormik<IsoFormValues>({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validationSchema: schemaIsoForm(Boolean(id)),
  })

  const debouncedBankRouting = useDebouncedValue(values.userPage.bankRouting, 1000)
  const debouncedBankRoutingWire = useDebouncedValue(values.userPage.bankRoutingWire, 1000)
  const [bankInfo, isLoadingBankInfo] = useValidateBankRouting(debouncedBankRouting)
  const [bankInfoWire, isLoadingBankInfoWire] = useValidateBankRouting(debouncedBankRoutingWire)
  const isLoadingAny = loading || isLoadingBankInfo || isLoadingBankInfoWire || isLoadingStates

  useEffect(() => {
    const entityState = statesData?.data.find((state) => state.id.toString() === values.stateId)
    if (entityState) setFieldValue('userPage.entityState', entityState)
    if (bankInfo) setFieldValue('userPage.bankInfo', bankInfo)
    if (bankInfoWire) setFieldValue('userPage.bankInfoWire', bankInfoWire)
  }, [values.stateId, bankInfo, bankInfoWire, statesData?.data])

  return (
    <form onSubmit={handleSubmit}>
      <Grid className={additionalStyle.wrapper} container spacing={4}>
        <Grid item xs={6}>
          <Paper elevation={0}>
            <Box p="3rem">
              <Typography variant="h2" color="textSecondary">
                {label}
              </Typography>
              <Box mt="3rem">
                <TextField
                  value={values.businessName}
                  onChange={handleChange}
                  error={Boolean(errors.businessName && touched.businessName)}
                  helperText={touched.businessName && errors.businessName}
                  name="businessName"
                  label={<RequiredOption label="Business Name" />}
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Box mt="3rem">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      value={values.firstName}
                      onChange={handleChange}
                      error={Boolean(errors.firstName && touched.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      name="firstName"
                      label={<RequiredOption label="First name" />}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={<RequiredOption label="Last name" />}
                      variant="outlined"
                      fullWidth
                      value={values.lastName}
                      onChange={handleChange}
                      error={Boolean(errors.lastName && touched.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      name="lastName"
                    />
                  </Grid>
                </Grid>
                <Box mt="2rem">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <ReactInputMask name="phone" mask="(999)-999-9999" value={values.phone} onChange={handleChange}>
                        {(props: any) => (
                          <TextField
                            {...props}
                            error={Boolean(errors.phone && touched.phone)}
                            helperText={touched.phone && errors.phone}
                            label={<RequiredOption label="Phone" />}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <ReactInputMask name="mobile" mask="(999)-999-9999" value={values.mobile} onChange={handleChange}>
                        {(props: any) => (
                          <TextField
                            {...props}
                            error={Boolean(errors.mobile && touched.mobile)}
                            helperText={touched.mobile && errors.mobile}
                            label="Mobile"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        name="fax"
                        value={values.fax}
                        onChange={handleChange}
                        label="Fax"
                        variant="outlined"
                        fullWidth
                        error={Boolean(errors.fax && touched.fax)}
                        helperText={touched.fax && errors.fax}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled={Boolean(id)}
                        label={<RequiredOption label="Email" />}
                        variant="outlined"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        error={Boolean(errors.email && touched.email)}
                        helperText={touched.email && errors.email}
                        name="email"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={!!touched.street && !!errors.street}
                        helperText={touched.street && errors.street}
                        value={values.street}
                        name="street"
                        onChange={handleChange}
                        fullWidth
                        label={<RequiredOption label="Street" />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={!!touched.city && !!errors.city}
                        helperText={touched.city && errors.city}
                        value={values.city}
                        name="city"
                        onChange={handleChange}
                        fullWidth
                        label={<RequiredOption label="City" />}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <SelectComponent
                        error={!!touched.stateId && !!errors.stateId}
                        helperText={touched.stateId && errors.stateId}
                        value={values.stateId}
                        name="stateId"
                        onChange={handleChange}
                        fullWidth
                        label={<RequiredOption label="State" />}
                      >
                        {statesData?.data.map((state) => (
                          <MenuItem value={state.id}>{state.name}</MenuItem>
                        ))}
                      </SelectComponent>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={!!touched.zipCode && !!errors.zipCode}
                        helperText={touched.zipCode && errors.zipCode}
                        value={values.zipCode}
                        name="zipCode"
                        onChange={handleChange}
                        fullWidth
                        label={<RequiredOption label="Zip Code" />}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <ReactInputMask name="federalTaxId" mask="99-9999999" value={values.federalTaxId} onChange={handleChange}>
                        {(props: any) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            error={!!touched.federalTaxId && !!errors.federalTaxId}
                            helperText={touched.federalTaxId && errors.federalTaxId}
                            fullWidth
                            label={<RequiredOption label="Federal Tax ID" />}
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            elevation={0}
            style={{
              height: '100%',
            }}
          >
            <Box p="3rem" flexDirection="column" display="flex" height="inherit">
              <Box>
                <Typography variant="h2" color="textSecondary">
                  Bank Information
                </Typography>
                <Box mt="3rem">
                  <Grid container spacing={4} className={styles.bankInfoContainer}>
                    <Grid item xs={6}>
                      <TextField
                        value={values.userPage.bankName}
                        name="userPage.bankName"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label={<RequiredOption label="Bank Name" />}
                        error={Boolean(touched.userPage?.bankName && errors.userPage?.bankName)}
                        helperText={touched.userPage?.bankName && errors.userPage?.bankName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                disabled={isLoadingAny || !values.userPage?.bankInfo?.bankName}
                                size="small"
                                variant="text"
                                style={{ minWidth: 0 }}
                                onClick={() => setFieldValue('userPage.bankName', values.userPage?.bankInfo?.bankName)}
                              >
                                Autofill
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReactInputMask
                        name="userPage.bankRouting"
                        mask="999999999"
                        maskPlaceholder={null}
                        value={values.userPage.bankRouting}
                        onChange={handleChange}
                      >
                        {(props: any) => (
                          <TextFieldWithPreloader
                            variant="outlined"
                            error={!!touched.userPage?.bankRouting && !!errors.userPage?.bankRouting}
                            helperText={touched.userPage?.bankRouting && errors.userPage?.bankRouting}
                            fullWidth
                            label={<RequiredOption label="ACH Bank Routing #" />}
                            isLoading={isLoadingBankInfo || isLoadingStates}
                            isValid={!errors.userPage?.bankRouting && !!bankInfo?.bankName}
                            {...props}
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={values.userPage.bankAccountName}
                        name="userPage.bankAccountName"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label={<RequiredOption label="Bank Account Name" />}
                        error={Boolean(touched.userPage?.bankAccountName && errors.userPage?.bankAccountName)}
                        helperText={touched.userPage?.bankAccountName && errors.userPage?.bankAccountName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReactInputMask
                        name="userPage.bankRoutingWire"
                        mask="999999999"
                        maskPlaceholder={null}
                        value={values.userPage.bankRoutingWire}
                        onChange={handleChange}
                      >
                        {(props: any) => (
                          <TextFieldWithPreloader
                            variant="outlined"
                            error={!!touched.userPage?.bankRoutingWire && !!errors.userPage?.bankRoutingWire}
                            helperText={touched.userPage?.bankRoutingWire && errors.userPage?.bankRoutingWire}
                            fullWidth
                            label="Wire Bank Routing #"
                            isLoading={isLoadingBankInfoWire || isLoadingStates}
                            isValid={!errors.userPage?.bankRoutingWire && !!bankInfoWire?.bankName}
                            {...props}
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={values.userPage.bankAccount}
                        name="userPage.bankAccount"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label={<RequiredOption label="Bank Account #" />}
                        error={Boolean(touched.userPage?.bankAccount && errors.userPage?.bankAccount)}
                        helperText={touched.userPage?.bankAccount && errors.userPage?.bankAccount}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled={Boolean(id)}
                        value={values.userPage.availableBalance}
                        name="userPage.availableBalance"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label={<RequiredOption label="Starting Balance" />}
                        error={Boolean(touched.userPage?.availableBalance && errors.userPage?.availableBalance)}
                        helperText={touched.userPage?.availableBalance && errors.userPage?.availableBalance}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={values.nameOnCheck}
                        name="nameOnCheck"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label="Name on Voided Check"
                        error={Boolean(touched.nameOnCheck && errors.nameOnCheck)}
                        helperText={touched.nameOnCheck && errors.nameOnCheck}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-end" flex={1}>
                <Box mr={5}>
                  <Button variant="contained" color="secondary" onClick={() => goBack()} loading={isLoadingAny}>
                    Cancel
                  </Button>
                </Box>
                <Button variant="contained" color="primary" type="submit" loading={isLoadingAny}>
                  Complete
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  )
}

export default ISOForm
