import { fade, makeStyles } from '@material-ui/core/styles'
import { bg2, bg3 } from 'containers/MaterialUiContainer/mainTheme'

export const useStyles = makeStyles((theme) => ({
  iconButton: {
    boxShadow: '0px 1px 3px #DBDFE7',
    backgroundColor: theme.palette.common.white,
  },
  blueRoundedBlock: {
    backgroundColor: bg3,
    borderRadius: '0.5rem',
  },
  clientName: {
    fontSize: '2.25rem',
    maxWidth: '20rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  sectionLabel: {
    fontSize: '1.5rem',
  },
  contactIcon: {
    marginRight: '1rem',
  },
  contactText: {
    fontSize: '1.1875rem',
  },
  popover: {
    overflow: 'inherit',
    '&:after': {
      width: 0,
      height: 0,
      borderLeft: '0.8rem solid transparent',
      borderRight: '0.8rem solid transparent',
      borderBottom: `0.8rem solid ${theme.palette.common.white}`,
      top: '-0.8rem',
      right: '1.2rem',
      display: 'table',
      content: '""',
      position: 'absolute',
    },
  },
  menuItem: {
    height: '3.25rem',
  },
  iconContainer: {
    border: `1px solid ${fade(theme.palette.secondary.main, 0.2)}`,
    background: bg2,
    padding: '0.4rem',
    marginRight: '0.75rem',
    borderRadius: '0.375rem',
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  roundBorder: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '6px',
  },
}))

export const usePopoverStyles = makeStyles((theme) => ({
  popover: {
    overflow: 'inherit',
    '&:after': {
      width: 0,
      height: 0,
      borderLeft: '0.8rem solid transparent',
      borderRight: '0.8rem solid transparent',
      borderBottom: `0.8rem solid ${theme.palette.common.white}`,
      top: '-0.8rem',
      right: '1.5rem',
      display: 'table',
      content: '""',
      position: 'absolute',
    },
  },
  menuItem: {
    position: 'relative',
    height: '3.5rem',
  },
  iconContainer: {
    border: `1px solid ${fade(theme.palette.secondary.main, 0.2)}`,
    background: bg2,
    padding: '0.4rem',
    marginRight: '0.75rem',
    borderRadius: '0.375rem',
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dividerRoot: {
    margin: '1rem auto',
    width: 'calc(100% - 4rem)',
  },
}))
