import React, { FC, useContext, useEffect, useState } from 'react'
import { FormikErrors, useFormik } from 'formik'
import SelectComponent from 'UI/Select'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import RequiredOption from 'UI/Select/RequiredOption'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from 'UI/Checkbox'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/svg/ArrowRightIcon.svg'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import Button from 'UI/Button/ButtonWithPreloader'
import DatePicker from 'UI/DatePicker/DatePicker'
import { ClientType, EntityType, Grade, Guarantor, IClientInitValue } from 'typescript/interfaces/clients'
import { useIndustries } from 'hooks/useIndustries'
import { useStates } from 'hooks/useStates'
import ReactInputMask from 'react-input-mask'
import { schemaClientInfo, schemaEditClient } from 'components/ClientForms/ClientInfoForm/schema'
import { UserContext } from 'contexts/userContext'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_CREATE_PERSONAL_CLIENT } from 'constants/permissions'
import { bankAccountTypes } from 'constants/bankAccountType'
import { useParams } from 'react-router-dom'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'
import { Card, CardContent, CardHeader, IconButton, InputAdornment, makeStyles, Popover, useTheme } from '@material-ui/core'
import TextFieldWithPreloader from 'UI/TextField/TextFieldWithPreloader'
import useDebouncedValue from 'hooks/Custom/useDebouncedValue'
import useValidateBankRouting, { IValidateBankRouting } from 'hooks/useValidateBankRouting'
import { IBankAccount } from 'typescript/interfaces/companies'
import { AddGuarantorForm } from './AddGuarantorForm'

export const useStyles = makeStyles(() => ({
  root: {
    width: 300,
    padding: 20,
  },
  popoverTextField: {
    marginTop: 20,
    marginBottom: 20,
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  cardRoot: {
    maxWidth: 200,
    marginRight: '8px',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  title: {
    overflow: 'hidden',
    marginBottom: '4px',
  },
  svg: {
    '& path': {
      fill: '#738BA5',
    },
  },
}))

type IClientInfoForm = Omit<IClientInitValue, 'bankAccount'> & {
  bankAccount: IBankAccount & IValidateBankRouting
}

interface IClientInfoFormProps {
  initialValues?: IClientInitValue
  loading?: boolean
  submit: {
    label: string
    handler: (values: IClientInitValue) => any
  }
  back: {
    label: string
    handler: () => any
  }
  formErrors?: FormikErrors<IClientInfoForm>
}

const defaultInitialsValues: IClientInitValue = {
  clientType: '',
  businessName: '',
  entityType: '',
  doingBusinessAs: '',
  businessStartDate: '',
  grossAnnualIncome: '',
  federalTaxId: '',
  expectedGrade: null,
  phone: '',
  homePhone: '',
  businessPhone: '',
  fax: '',
  email: '',
  webSite: '',
  isCrossCollateralize: false,
  industryId: '',
  phisicalStateId: '',
  city: '',
  street: '',
  zipCode: '',
  mailingStateId: null,
  mailingCity: '',
  mailingStreet: '',
  mailingZipCode: '',
  landlordOrMortgageCo: '',
  landlordContactName: '',
  landlordPhone: '',
  landlordFax: '',
  rentOrMortgagePayment: '',
  miCampId: '',
  leaseExpiration: null,
  isMailingAddressDifferent: false,
  additionalGuarantors: null,
  bankAccount: {
    bankAccount: '',
    bankAccountName: '',
    bankRouting: '',
    bankRoutingWire: '',
    availableBalance: '',
    bankAccountType: '',
    bankName: '',
  },
}

const ClientInfoForm: FC<IClientInfoFormProps> = ({ initialValues = defaultInitialsValues, back, submit, loading, formErrors }) => {
  const userContext = useContext(UserContext)
  const { hasPermission } = usePermission()
  const s = useStyles()
  const theme = useTheme()
  const { user } = userContext.state
  const clientId = useParams<{ id: string }>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [guarantors, setGuarantors] = useState<Guarantor[]>([])
  const [homePhoneShrink, setHomePhoneShrink] = useState<boolean>(!!initialValues.homePhone)

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }
  const handleDeleteGuarantor = (ownerName: string, businessName: string) => {
    const filteredGuarantors = guarantors.filter((guarantor) => guarantor.ownerName !== ownerName && guarantor.businessName !== businessName)

    setGuarantors(filteredGuarantors)
  }
  const open = Boolean(anchorEl)
  const { handleSubmit, values, handleChange, errors, touched, setFieldValue, setValues, setErrors } = useFormik<IClientInfoForm>({
    initialValues: {
      ...initialValues,
      email: hasPermission(PERMISSION_CREATE_PERSONAL_CLIENT) && user ? user.email : initialValues.email,
    },
    // enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (formValues.isCrossCollateralize) {
        const copiedValues = {
          ...formValues,
          additionalGuarantors: guarantors,
        }
        submit.handler(copiedValues)
      } else {
        submit.handler(formValues)
      }
    },
    validationSchema: clientId.id ? schemaEditClient : schemaClientInfo,
  })

  const { data: dataIndustries } = useIndustries()
  const { data: dataStates, isLoading: isLoadingStates } = useStates()

  const debouncedBankRouting = useDebouncedValue(values.bankAccount.bankRouting, 1000)
  const debouncedBankRoutingWire = useDebouncedValue(values.bankAccount.bankRoutingWire, 1000)
  const [bankInfo, isLoadingBankInfo] = useValidateBankRouting(debouncedBankRouting)
  const [bankInfoWire, isLoadingBankInfoWire] = useValidateBankRouting(debouncedBankRoutingWire)
  const isLoadingAny = loading || isLoadingBankInfo || isLoadingBankInfoWire

  useEffect(() => {
    const entityState = dataStates?.data.find((state) => state.id.toString() === values.phisicalStateId)
    if (entityState) setFieldValue('bankAccount.entityState', entityState.abbreviation)
    if (bankInfo) setFieldValue('bankAccount.bankInfo', bankInfo)
    if (bankInfoWire) setFieldValue('bankAccount.bankInfoWire', bankInfoWire)
  }, [values.phisicalStateId, bankInfo, bankInfoWire, dataStates?.data])

  useEffect(() => {
    if (values.additionalGuarantors) {
      setGuarantors(values.additionalGuarantors)
    }
  }, [])

  useEffect(() => {
    if (formErrors) {
      setErrors(formErrors)
    }
  }, [formErrors])

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h3" color="textSecondary">
        General Info
      </Typography>
      <Box mt="1.5rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <SelectComponent
              label={<RequiredOption label="Client Type" />}
              value={values.clientType}
              name="clientType"
              onChange={handleChange}
              error={Boolean(errors.clientType && touched.clientType)}
              helperText={touched.clientType && errors.clientType}
            >
              {Object.keys(ClientType).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
          <Grid item xs={4}>
            <SelectComponent
              name="industryId"
              value={values.industryId}
              error={Boolean(errors.industryId && touched.industryId)}
              helperText={touched.industryId && errors.industryId}
              label={<RequiredOption label="Industry" />}
              onChange={handleChange}
            >
              {dataIndustries &&
                dataIndustries.data.map((ind) => (
                  <MenuItem key={ind.id} value={ind.id}>
                    {ind.name}
                  </MenuItem>
                ))}
            </SelectComponent>
          </Grid>
          <Grid item xs={4}>
            <SelectComponent
              name="entityType"
              value={values.entityType}
              error={Boolean(errors.entityType && touched.entityType)}
              helperText={touched.entityType && errors.entityType}
              onChange={handleChange}
              label={<RequiredOption label="Entity Type" />}
            >
              {Object.keys(EntityType).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
        </Grid>
        <Box mt="1rem">
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <TextField
                name="businessName"
                value={values.businessName}
                InputProps={{
                  style: {
                    fontFamily: 'system-ui', //To show system symbols
                  },
                }}
                onChange={handleChange}
                error={Boolean(errors.businessName && touched.businessName)}
                helperText={touched.businessName && errors.businessName}
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Business Name" />}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="doingBusinessAs"
                value={values.doingBusinessAs}
                onChange={handleChange}
                InputProps={{
                  style: {
                    fontFamily: 'system-ui', //To show system symbols
                  },
                }}
                error={Boolean(errors.doingBusinessAs && touched.doingBusinessAs)}
                helperText={touched.doingBusinessAs && errors.doingBusinessAs}
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Doing Business As" />}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                disableFuture
                name="businessStartDate"
                value={values.businessStartDate ? values.businessStartDate : null}
                onChange={(date) => setFieldValue('businessStartDate', convertDateForPicker(date))}
                error={Boolean(touched.businessStartDate && errors.businessStartDate) || !isValidDate(values.businessStartDate)}
                helperText={(touched.businessStartDate && errors.businessStartDate) || (!isValidDate(values.businessStartDate) && 'MM/DD/YYYY')}
                inputVariant="outlined"
                variant="dialog"
                type="keyboard"
                label={<RequiredOption label="Business Start Date" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mx="-3rem" mt="3.5rem">
        <Divider />
      </Box>
      <Box mt="3.5rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <TextField
              name="grossAnnualIncome"
              value={values.grossAnnualIncome}
              onChange={handleChange}
              error={Boolean(errors.grossAnnualIncome && touched.grossAnnualIncome)}
              helperText={touched.grossAnnualIncome && errors.grossAnnualIncome}
              fullWidth
              variant="outlined"
              label="Gross Annual Income"
            />
          </Grid>
          <Grid item xs={4}>
            <ReactInputMask name="federalTaxId" mask="99-9999999" value={values.federalTaxId} onChange={handleChange}>
              {(props: any) => (
                <TextField
                  {...props}
                  variant="outlined"
                  error={!!touched.federalTaxId && !!errors.federalTaxId}
                  helperText={touched.federalTaxId && errors.federalTaxId}
                  fullWidth
                  label={<RequiredOption label="Federal Tax ID" />}
                />
              )}
            </ReactInputMask>
          </Grid>
        </Grid>
      </Box>
      <Box mt="3rem" mx="-3rem">
        <Divider />
      </Box>
      <Box mt="3rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <SelectComponent
              name="expectedGrade"
              value={values.expectedGrade}
              onChange={handleChange}
              error={Boolean(errors.expectedGrade && touched.expectedGrade)}
              helperText={touched.expectedGrade && errors.expectedGrade}
              label="Expected Grade"
            >
              {Object.keys(Grade).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="webSite"
              value={values.webSite}
              onChange={handleChange}
              error={Boolean(errors.webSite && touched.webSite)}
              helperText={touched.webSite && errors.webSite}
              fullWidth
              variant="outlined"
              label="Website"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="fax"
              value={values.fax}
              onChange={handleChange}
              error={Boolean(errors.fax && touched.fax)}
              helperText={touched.fax && errors.fax}
              fullWidth
              variant="outlined"
              label="FAX"
            />
          </Grid>
          <Grid item xs={4}>
            <ReactInputMask name="businessPhone" mask="(999)-999-9999" value={values.businessPhone ?? ''} onChange={handleChange}>
              {(props: any) => (
                <TextField
                  {...props}
                  error={Boolean(errors.businessPhone && touched.businessPhone)}
                  helperText={touched.businessPhone && errors.businessPhone}
                  label={<RequiredOption label="Business Phone" />}
                  variant="outlined"
                  fullWidth
                />
              )}
            </ReactInputMask>
          </Grid>
          <Grid item xs={4}>
            <ReactInputMask name="phone" mask="(999)-999-9999" value={values.phone ?? ''} onChange={handleChange}>
              {(props: any) => (
                <TextField
                  {...props}
                  error={Boolean(errors.phone && touched.phone)}
                  helperText={touched.phone && errors.phone}
                  label="Cell Phone"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setFieldValue('phone', values.homePhone)} size="small">
                          <ArrowLeftIcon className={s.svg} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
          </Grid>
          <Grid item xs={4}>
            <ReactInputMask
              name="homePhone"
              mask="(999)-999-9999"
              value={values.homePhone ?? ''}
              onChange={handleChange}
              onFocus={() => setHomePhoneShrink(true)}
              onBlur={(e) => setHomePhoneShrink(!!e.target.value)}
            >
              {(props: any) => (
                <TextField
                  {...props}
                  error={Boolean(errors.homePhone && touched.homePhone)}
                  helperText={touched.homePhone && errors.homePhone}
                  label="Home Phone"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ style: { marginLeft: homePhoneShrink ? 'initial' : theme.spacing(6) }, shrink: homePhoneShrink }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            setHomePhoneShrink(!!values.phone)
                            setFieldValue('homePhone', values.phone)
                          }}
                          size="small"
                        >
                          <ArrowLeftIcon className={s.svg} style={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
          </Grid>
          <Grid item xs={4}>
            <Box mt="4px">
              <TextField
                disabled={
                  hasPermission(PERMISSION_CREATE_PERSONAL_CLIENT) ||
                  (Boolean(clientId.id) && !userContext.state.roles.isAdmin && !userContext.state.roles.isRep)
                }
                name="email"
                value={values.email}
                onChange={handleChange}
                error={Boolean(errors.email && touched.email)}
                helperText={touched.email && errors.email}
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Email" />}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt="2.5rem">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox name="isCrossCollateralize" onChange={handleChange} checked={values.isCrossCollateralize} color="primary" />}
              label={<Typography color="textSecondary">Cross-collateralize</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" variant="contained" disabled={!values.isCrossCollateralize} onClick={handleOpenPopover}>
              + Add Guarantor
            </Button>
            <Popover
              id={clientId.id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              PaperProps={{ classes: { root: s.root } }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <AddGuarantorForm
                initialValues={{ businessName: '', ownerName: '', ein: '', id: 0 }}
                key="additionalGuarantors"
                disabled={!values.isCrossCollateralize}
                guarantors={guarantors}
                handleClosePopover={handleClosePopover}
                setGuarantors={setGuarantors}
                propsFormValues={values}
                classes={{ root: s.popoverTextField, container: s.popoverContainer }}
                name="additionalGuarantors"
              />
            </Popover>
          </Grid>
          <Grid item xs={12}>
            <div className={s.cardWrapper}>
              {guarantors.map(({ id, ownerName, businessName, ein }: Guarantor) => (
                <Card className={s.cardRoot}>
                  <CardHeader
                    action={
                      <IconButton
                        onClick={() => {
                          handleDeleteGuarantor(ownerName, businessName)
                        }}
                        aria-label="settings"
                      >
                        <CancelIcon />
                      </IconButton>
                    }
                    title="Guarantor"
                  />
                  <CardContent>
                    <Typography variant="h4" color="textSecondary" className={s.title}>
                      {businessName}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={s.title}>
                      {ownerName}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={s.title}>
                      {ein}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </div>
          </Grid>
        </Grid>
      </Box>
      {!clientId.id && (
        <>
          <Box mt="3rem" mx="-3rem">
            <Divider />
          </Box>
          <Box mt="3rem">
            <Typography variant="h3" color="textSecondary">
              Bank Account
            </Typography>
          </Box>
          <Box mt="3rem">
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <SelectComponent
                  name="bankAccount.bankAccountType"
                  onChange={handleChange}
                  value={values.bankAccount.bankAccountType}
                  error={Boolean(touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType)}
                  helperText={touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType}
                  fullWidth
                  label={<RequiredOption label="Bank Account Type" />}
                >
                  {bankAccountTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </SelectComponent>
              </Grid>
              <Grid item xs={4}>
                <TextFieldWithPreloader
                  variant="outlined"
                  fullWidth
                  name="bankAccount.bankRouting"
                  onChange={handleChange}
                  value={values.bankAccount.bankRouting}
                  error={Boolean(touched.bankAccount?.bankRouting && errors.bankAccount?.bankRouting)}
                  helperText={touched.bankAccount?.bankRouting && errors.bankAccount?.bankRouting}
                  label={<RequiredOption label="ACH Bank Routing #" />}
                  isLoading={isLoadingBankInfo || isLoadingStates}
                  isValid={!errors.bankAccount?.bankRouting && !!bankInfo?.bankName}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldWithPreloader
                  variant="outlined"
                  fullWidth
                  name="bankAccount.bankRoutingWire"
                  onChange={handleChange}
                  value={values.bankAccount.bankRoutingWire}
                  error={Boolean(touched.bankAccount?.bankRoutingWire && errors.bankAccount?.bankRoutingWire)}
                  helperText={touched.bankAccount?.bankRoutingWire && errors.bankAccount?.bankRoutingWire}
                  label={<RequiredOption label="Wire Bank Routing #" />}
                  isLoading={isLoadingBankInfoWire || isLoadingStates}
                  isValid={!errors.bankAccount?.bankRoutingWire && !!bankInfoWire?.bankName}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt="1rem">
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="bankAccount.bankAccount"
                  onChange={handleChange}
                  value={values.bankAccount.bankAccount}
                  error={Boolean(touched.bankAccount?.bankAccount && errors.bankAccount?.bankAccount)}
                  helperText={touched.bankAccount?.bankAccount && errors.bankAccount?.bankAccount}
                  label={<RequiredOption label="Bank Account #" />}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="bankAccount.bankName"
                  onChange={handleChange}
                  value={values.bankAccount.bankName}
                  error={Boolean(touched.bankAccount?.bankName && errors.bankAccount?.bankName)}
                  helperText={touched.bankAccount?.bankName && errors.bankAccount?.bankName}
                  label={<RequiredOption label="Bank Name" />}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disabled={isLoadingAny || !values.bankAccount?.bankInfo?.bankName}
                          size="small"
                          variant="text"
                          style={{ minWidth: 0 }}
                          onClick={() => setFieldValue('bankAccount.bankName', values.bankAccount?.bankInfo?.bankName)}
                        >
                          Autofill
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="bankAccount.bankAccountName"
                  onChange={handleChange}
                  value={values.bankAccount.bankAccountName}
                  error={Boolean(touched.bankAccount?.bankAccountName && errors.bankAccount?.bankAccountName)}
                  helperText={touched.bankAccount?.bankAccountName && errors.bankAccount?.bankAccountName}
                  label={<RequiredOption label="Bank Account Name" />}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Box mt="3rem" mx="-3rem">
        <Divider />
      </Box>
      <Box mt="3rem">
        <Typography variant="h3" color="textSecondary">
          Physical Address
        </Typography>
      </Box>
      <Box mt="3rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <TextField
              name="street"
              value={values.street}
              onChange={handleChange}
              error={Boolean(errors.street && touched.street)}
              helperText={touched.street && errors.street}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="Street" />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="city"
              value={values.city}
              onChange={handleChange}
              error={Boolean(errors.city && touched.city)}
              helperText={touched.city && errors.city}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="City" />}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectComponent
              name="phisicalStateId"
              value={values.phisicalStateId}
              onChange={handleChange}
              error={Boolean(errors.phisicalStateId && touched.phisicalStateId)}
              helperText={touched.phisicalStateId && errors.phisicalStateId}
              label={<RequiredOption label="State" />}
            >
              {dataStates &&
                dataStates.data.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
            </SelectComponent>
          </Grid>
        </Grid>
        <Box mt="1rem">
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <TextField
                name="zipCode"
                value={values.zipCode}
                onChange={handleChange}
                error={Boolean(errors.zipCode && touched.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Zip Code" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt="2.5rem">
        <FormControlLabel
          control={
            <Checkbox
              name="isMailingAddressDifferent"
              onChange={(e) =>
                setValues((prevState) => ({
                  ...prevState,
                  isMailingAddressDifferent: e.target.checked,
                  mailingStreet: '',
                  mailingCity: '',
                  mailingStateId: null,
                  mailingZipCode: '',
                  landlordOrMortgageCo: '',
                  landlordContactName: '',
                  landlordPhone: '',
                  landlordFax: '',
                  rentOrMortgagePayment: '',
                  miCampId: '',
                  leaseExpiration: null,
                }))
              }
              checked={values.isMailingAddressDifferent}
              color="primary"
            />
          }
          label={<Typography color="textSecondary">The Mailing Adress is Different</Typography>}
        />
      </Box>
      {values.isMailingAddressDifferent && (
        <>
          <Box mt="3rem">
            <Divider />
          </Box>
          <Box mt="3rem">
            <Typography variant="h3" color="textSecondary">
              Mailing Address
            </Typography>
          </Box>
          <Box mt="3rem">
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <TextField
                  name="mailingStreet"
                  value={values.mailingStreet}
                  onChange={handleChange}
                  error={Boolean(errors.mailingStreet && touched.mailingStreet)}
                  helperText={touched.mailingStreet && errors.mailingStreet}
                  fullWidth
                  variant="outlined"
                  label={<RequiredOption label="Mailing Street" />}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="mailingCity"
                  value={values.mailingCity}
                  onChange={handleChange}
                  error={Boolean(errors.mailingCity && touched.mailingCity)}
                  helperText={touched.mailingCity && errors.mailingCity}
                  fullWidth
                  variant="outlined"
                  label={<RequiredOption label="Mailing City" />}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectComponent
                  name="mailingStateId"
                  value={values.mailingStateId}
                  onChange={handleChange}
                  error={Boolean(errors.mailingStateId && touched.mailingStateId)}
                  helperText={touched.mailingStateId && errors.mailingStateId}
                  label={<RequiredOption label="Mailing State" />}
                >
                  {dataStates &&
                    dataStates.data.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                </SelectComponent>
              </Grid>
            </Grid>
            <Box mt="1rem">
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <TextField
                    name="mailingZipCode"
                    value={values.mailingZipCode}
                    onChange={handleChange}
                    error={Boolean(errors.mailingZipCode && touched.mailingZipCode)}
                    helperText={touched.mailingZipCode && errors.mailingZipCode}
                    fullWidth
                    variant="outlined"
                    label={<RequiredOption label="Mailing Zip Code" />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="landlordOrMortgageCo"
                    value={values.landlordOrMortgageCo}
                    onChange={handleChange}
                    error={Boolean(errors.landlordOrMortgageCo && touched.landlordOrMortgageCo)}
                    helperText={touched.landlordOrMortgageCo && errors.landlordOrMortgageCo}
                    fullWidth
                    variant="outlined"
                    label={<RequiredOption label="Landlord / Mortgage Co." />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="landlordContactName"
                    value={values.landlordContactName}
                    onChange={handleChange}
                    error={Boolean(errors.landlordContactName && touched.landlordContactName)}
                    helperText={touched.landlordContactName && errors.landlordContactName}
                    fullWidth
                    variant="outlined"
                    label={<RequiredOption label="Landlord Contact Name" />}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt="1rem">
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <ReactInputMask name="landlordPhone" mask="(999)-999-9999" value={values.landlordPhone} onChange={handleChange}>
                    {(props: any) => (
                      <TextField
                        {...props}
                        error={Boolean(errors.landlordPhone && touched.landlordPhone)}
                        helperText={touched.landlordPhone && errors.landlordPhone}
                        label={<RequiredOption label="Landlord Phone" />}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </ReactInputMask>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="landlordFax"
                    value={values.landlordFax}
                    onChange={handleChange}
                    error={Boolean(errors.landlordFax && touched.landlordFax)}
                    helperText={touched.landlordFax && errors.landlordFax}
                    fullWidth
                    variant="outlined"
                    label={<RequiredOption label="Landlord Fax" />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="rentOrMortgagePayment"
                    value={values.rentOrMortgagePayment}
                    onChange={handleChange}
                    error={Boolean(errors.rentOrMortgagePayment && touched.rentOrMortgagePayment)}
                    helperText={touched.rentOrMortgagePayment && errors.rentOrMortgagePayment}
                    fullWidth
                    variant="outlined"
                    label={<RequiredOption label="Rent / Mortgage Payment" />}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt="1rem">
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <TextField
                    name="miCampId"
                    value={values.miCampId}
                    onChange={handleChange}
                    error={Boolean(errors.miCampId && touched.miCampId)}
                    helperText={touched.miCampId && errors.miCampId}
                    fullWidth
                    variant="outlined"
                    label={<RequiredOption label="MiCamp ID" />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    name="leaseExpiration"
                    value={values.leaseExpiration ? values.leaseExpiration : null}
                    onChange={(date) => setFieldValue('leaseExpiration', convertDateForPicker(date))}
                    inputVariant="outlined"
                    variant="dialog"
                    type="keyboard"
                    label={<RequiredOption label="Lease Expiration" />}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
      <Box mt="3rem" display="flex" alignItems="center">
        {!userContext.state.roles.isClient && (
          <Box mr={5}>
            <Button loading={isLoadingAny} color="secondary" variant="contained" onClick={back.handler} startIcon={<ArrowLeftIcon />}>
              {back.label}
            </Button>
          </Box>
        )}
        <Button loading={isLoadingAny} color="primary" variant="contained" type="submit" endIcon={<ArrowRightIcon />}>
          {submit.label}
        </Button>
      </Box>
    </form>
  )
}

export default ClientInfoForm
