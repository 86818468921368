import { AxiosResponse } from 'axios'
import { API_CREATE_BANK, API_GET_BANK, API_GET_BANKS, API_UPDATE_BANK, API_VALIDATE_BANK_ROUTING } from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { IBankCreateEdit, IBankInfo, IBankResponse, SortFieldsBanks } from 'typescript/interfaces/banks'
import { IPagination } from 'typescript/interfaces/pagination'

export const createBank = (values: IBankCreateEdit) => privateInstance.post<IBankCreateEdit, AxiosResponse<IBankCreateEdit>>(API_CREATE_BANK, values)

export const updateBank = (values: IBankCreateEdit) => privateInstance.put<IBankCreateEdit, AxiosResponse<IBankCreateEdit>>(API_UPDATE_BANK, values)

export const getBank = (id: string) => privateInstance.get<IBankCreateEdit, AxiosResponse<IBankResponse>>(API_GET_BANK.replace(':id', id))

export const getBanks = (params: IPagination<SortFieldsBanks>) =>
  privateInstance.get<IBankCreateEdit, AxiosResponse<Array<IBankResponse>>>(API_GET_BANKS, { params })

export const validateBankRouting = (bankRouting: string) =>
  privateInstance.get<typeof bankRouting, AxiosResponse<IBankInfo>>(API_VALIDATE_BANK_ROUTING.replace(':bankRouting', bankRouting))
