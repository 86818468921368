import React, { ReactElement, useState } from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'

interface Props {
  initialValues: any
  name: string
  selectedReps: any[]
  propsFormValues: any
  disabled: boolean | undefined
  propsSetFieldValue: (name: string, data: any[]) => void
  handleClosePopover: () => void
  classes: any
}

export default function SplitCommissionForm({
  initialValues,
  selectedReps,
  propsFormValues,
  propsSetFieldValue,
  handleClosePopover,
  name,
  disabled,
  ...props
}: Props): ReactElement {
  const [error, setError] = useState<string | null>(null)

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues,
    enableReinitialize: false,
    onSubmit: (formValues) => {
      const data = []
      let sum = 0
      let haveNegativeNumber = false

      for (const [key, value] of Object.entries(formValues)) {
        const numberKey = Number(key)
        const numberValue = Number(value)

        data.push({ id: numberKey, value: numberValue })
      }

      sum = data.reduce((acc, el) => {
        if (el.value < 0) {
          haveNegativeNumber = true
        }

        return acc + el.value
      }, 0)

      if (sum > 100) {
        return setError('Percentage must not be more than 100%')
      }

      if (sum < 100) {
        return setError('Percentage must not be less than 100%')
      }

      if (sum === 100 && !haveNegativeNumber) {
        propsSetFieldValue(name, data)
        handleClosePopover()
      }
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      {selectedReps.map(({ id, name: repName }) => (
        <TextField
          {...props}
          disabled={disabled}
          variant="outlined"
          placeholder="Percentage"
          autoComplete={undefined}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={(!Number.isInteger(parseInt(values[id])) && values[id] !== '' && values[id] !== undefined) || parseInt(values[id]) < 0}
          helperText={
            (!Number.isInteger(parseInt(values[id])) && values[id] !== '' && values[id] !== undefined && 'Numbers only') ||
            (parseInt(values[id]) < 0 && 'Number should be positive')
          }
          name={`${id}`}
          key={`commission-percentage-${id}`}
          value={values[id]}
          onChange={handleChange}
          label={repName?.trim()}
        />
      ))}
      {error && (
        <Typography gutterBottom color="error">
          {error}
        </Typography>
      )}
      <Button type="submit" color="primary" variant="contained">
        Split
      </Button>
    </form>
  )
}
