import { Box, Grid, IconButton, Paper, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { Company, CompanyType } from 'typescript/interfaces/companies'
import { ReactComponent as EditPenIcon } from 'assets/svg/EditPenIcon.svg'
import { convertEmptyField } from 'utils/formatters'
import { useStyles } from './styles'

interface Props {
  company: Company
  onEdit?: () => void
}

const AllInfo = ({ company, onEdit }: Props) => {
  const s = useStyles()
  const theme = useTheme()
  return (
    <Box position="relative" component={Paper} p="3rem" pb="2rem" display="flex" justifyContent="space-between">
      {onEdit && (
        <Box position="absolute" top="1rem" right="1rem">
          <IconButton size="small" onClick={onEdit}>
            <EditPenIcon />
          </IconButton>
        </Box>
      )}

      <Box flex="1">
        <Typography className={s.blockTitle} variant="h2" color="textSecondary">
          General Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography className={s.rowTitle}>Company Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" color="textSecondary">
              {convertEmptyField(company.name)}
            </Typography>
          </Grid>
          {company.companyType === CompanyType.OutHouse && (
            <>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Name</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.firstName)} {convertEmptyField(company.lastName)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <Typography className={s.rowTitle}>Phone</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" color="textSecondary">
              {convertEmptyField(company.phone)}
            </Typography>
          </Grid>
          {company.companyType === CompanyType.OutHouse && (
            <>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Mobile</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.mobile)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <Typography className={s.rowTitle}>Email</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" color="textSecondary">
              {convertEmptyField(company.email)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={s.rowTitle}>Street</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" color="textSecondary">
              {convertEmptyField(company.street)}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography className={s.rowTitle}>City</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" color="textSecondary">
              {convertEmptyField(company.city)}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography className={s.rowTitle}>Zip Code</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" color="textSecondary">
              {convertEmptyField(company.zipCode)}
            </Typography>
          </Grid>
          {company.companyType === CompanyType.InHouse && (
            <>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Federal Tax ID</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.federalTaxId)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <Typography className={s.rowTitle}>State</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" color="textSecondary">
              {convertEmptyField(company.state?.name)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {company.companyType === CompanyType.InHouse && (
        <>
          <Box height="inherit" width="1px" bgcolor={theme.palette.divider} mx="2rem" />
          <Box flex="1">
            <Typography className={s.blockTitle} variant="h2" color="textSecondary">
              Bank Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Bank  ID</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.bankCompanyId)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Bank Name</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.bankName)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Bank Account Name</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.bankAccountName)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Bank Account Number</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.bankAccountNumber)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={s.rowTitle}>Bank Routing Number</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="textSecondary">
                  {convertEmptyField(company.bankRoutingNumber)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  )
}

export default AllInfo
