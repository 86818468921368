export function convertToEnum<TEnum extends Record<string, string>>(
  enumObj: TEnum,
  value: string | undefined | null
): TEnum[keyof TEnum] | undefined {
  return Object.values(enumObj).includes(value as TEnum[keyof TEnum])
    ? (value as TEnum[keyof TEnum])
    : undefined;
}

export function removeSpaces(
  value?: string
): string | undefined {
    if (!value) {
        return undefined;
    }

    return value.replace(/\s/g, '');
}