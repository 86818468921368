import * as yup from 'yup'
import {
  REQUIRED_FIELD,
  INVALID_PHONE_NUMBER,
  INVALID_EMAIL,
  INVALID_MOBILE_NUMBER,
  INVALID_FEDERAL_TAX_ID,
  ZIP_CODE_FORMAT_ERROR,
} from 'constants/errors'
import { userPageSchema } from 'components/VendorForm/schema'
import { zipCodeReg } from 'constants/regExp'

export const schemaIsoForm = (isEdit: boolean) =>
  yup.object().shape({
    email: yup.string().required(REQUIRED_FIELD).email(INVALID_EMAIL),
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    fax: yup.string(),
    nameOnCheck: yup.string(),
    phone: yup
      .string()
      .required(REQUIRED_FIELD)
      .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
    mobile: yup
      .string()
      .test('isFullMobilePhoneNumber', INVALID_MOBILE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0) || !val),
    userPage: userPageSchema(isEdit, 'ISO'),
    businessName: yup.string().required(REQUIRED_FIELD),
    city: yup.string().required(REQUIRED_FIELD),
    street: yup.string().required(REQUIRED_FIELD),
    stateId: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    federalTaxId: yup
      .string()
      .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
      .required(REQUIRED_FIELD),
    zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  })
