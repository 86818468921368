import React, { FC, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import ButtonWithPreloader from 'UI/Button/ButtonWithPreloader'
import RequiredOption from 'UI/Select/RequiredOption'
import DatePicker from 'UI/DatePicker/DatePicker'
import convertDateForPicker from 'utils/convertDateForPicker'
import SelectComponent from 'UI/Select'
import { paymentFrequency } from 'constants/paymentFrequency'
import { IMerchantPosition } from 'typescript/interfaces/deals'
import * as yup from 'yup'
import { NUMBER_CANNOT_BE_NEGATIVE, NUMBERS_ONLY, ONLY_DIGITS, REQUIRED_FIELD } from 'constants/errors'
import { integerReg } from 'constants/regExp'
import isValidDate from 'utils/isValidDate'
import FileDragNDrop from 'components/FileDragNDrop'
import { useMutation } from 'react-query'
import { getDownloadUrl, uploadToS3 } from 'services/storage'
import { useSnackbar } from 'notistack'
import { paymentFrequencyCalendarDays, paymentFrequencyInterval } from 'typescript/interfaces/paymentFrequency'
import useDebouncedEffect from 'hooks/Custom/useDebouncedEffect'
import { MsPerDay } from 'utils/dateUtils'
import { getPositionContractName } from './MerchantPositionsTable'

interface IMerchantPositionsForm {
  clientId: string
  initialValues?: IMerchantPosition
  onSubmit: (values: IMerchantPosition) => void
  onCancel: () => void
}

const validationSchema = yup.object().shape({
  // eslint-disable-next-line newline-per-chained-call
  advanceAmount: yup.string().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY).matches(integerReg, ONLY_DIGITS).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  // eslint-disable-next-line newline-per-chained-call
  factor: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY).min(1, 'Minimum value is 1').max(2, 'Maximum value is 2'),
  firstPaymentDate: yup.date().required(REQUIRED_FIELD),
  remaining: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY),
  daysLeft: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  paymentFrequency: yup.string().required(REQUIRED_FIELD).oneOf(paymentFrequency),
  frequentPayment: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  funder: yup.string().required(REQUIRED_FIELD),
  dateFunded: yup.date().required(REQUIRED_FIELD),
  netAmount: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY),
})

const defaultInitialValues: IMerchantPosition = {
  id: 0,
  advanceAmount: undefined,
  factor: undefined,
  firstPaymentDate: '',
  remaining: undefined,
  daysLeft: undefined,
  paymentFrequency: undefined,
  frequentPayment: undefined,
  internalDealId: undefined,
  funder: undefined,
  dateFunded: '',
  netAmount: undefined,
  contractUrl: undefined,
}

const MerchantPositionsForm: FC<IMerchantPositionsForm> = ({ onSubmit, onCancel, initialValues = defaultInitialValues }) => {
  const snack = useSnackbar()
  const [disableComputedFields, setDisableComputedFields] = useState<boolean>(true)

  const { values, errors, touched, dirty, handleChange, handleSubmit, setFieldValue } = useFormik<IMerchantPosition & { contract?: File }>({
    initialValues,
    validationSchema,
    enableReinitialize: false,
    onSubmit: async ({ contract, ...res }) => {
      if (!dirty) {
        onCancel()
        return
      }

      if (contract) {
        res.contractUrl = await uploadContract(contract)
      }

      onSubmit({
        ...res,
        advanceAmount: Number(res.advanceAmount),
        remaining: Number(res.remaining),
        frequentPayment: Number(res.frequentPayment),
        factor: Number(res.factor),
      })
    },
  })

  const [uploadContract, { isLoading }] = useMutation(
    async (file: File) => {
      const uploadUrl = await getDownloadUrl({ DocumentName: file.name, DocumentDestination: 'MerchantPosition', DestinationObjectId: 0 })
      await uploadToS3(file, uploadUrl.data)

      return uploadUrl.data.split('?')[0]
    },
    {
      onSuccess: (url) => setFieldValue('contractUrl', url),
      onError: () => {
        snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
      },
    },
  )

  const totalRtr = useMemo(() => Number(values.factor) * Number(values.advanceAmount) || 0, [values.factor, values.advanceAmount])

  useDebouncedEffect(() => {
    if (!totalRtr || !values.firstPaymentDate || !values.paymentFrequency || !values.frequentPayment) {
      setFieldValue('daysLeft', '')
      setFieldValue('remaining', '')
      setDisableComputedFields(true)
      return
    }

    setDisableComputedFields(false)

    let remaining = totalRtr
    const today = new Date().setUTCHours(0, 0, 0, 0)
    let nextPaymentDate = new Date(values.firstPaymentDate).setUTCHours(0, 0, 0, 0)
    while (nextPaymentDate < today) {
      const dayOfWeek = new Date(nextPaymentDate).getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) remaining -= Number(values.frequentPayment)
      nextPaymentDate += paymentFrequencyCalendarDays[values.paymentFrequency] * MsPerDay
    }

    setFieldValue('remaining', remaining)

    let weekdaysLeft = 0
    while (remaining > 0) {
      remaining -= Number(values.frequentPayment)
      weekdaysLeft += paymentFrequencyInterval[values.paymentFrequency]
    }

    setFieldValue('daysLeft', weekdaysLeft)
  }, [500, totalRtr, values.firstPaymentDate, values.paymentFrequency, values.frequentPayment])

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={6}>
        <Typography variant="body1">Merchant position</Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            name="funder"
            onChange={handleChange}
            value={values.funder ?? ''}
            label={<RequiredOption label="Funder" />}
            InputLabelProps={{ shrink: true }}
            error={Boolean(touched.funder && errors.funder)}
            helperText={touched.funder && errors.funder}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            name="advanceAmount"
            onChange={handleChange}
            value={values.advanceAmount ?? ''}
            error={Boolean(touched.advanceAmount && errors.advanceAmount)}
            helperText={touched.advanceAmount && errors.advanceAmount}
            label={<RequiredOption label="Initial Advance Amount" />}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            name="factor"
            onChange={handleChange}
            value={values.factor ?? ''}
            error={Boolean(touched.factor && errors.factor)}
            helperText={touched.factor && errors.factor}
            label={<RequiredOption label="Factor Rate" />}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            name="netAmount"
            onChange={handleChange}
            value={values.netAmount ?? ''}
            error={Boolean(touched.netAmount && errors.netAmount)}
            helperText={touched.netAmount && errors.netAmount}
            label={<RequiredOption label="Net Amount" />}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            type="keyboard"
            disableFuture
            label={<RequiredOption label="Date Funded" />}
            InputLabelProps={{ shrink: true }}
            name="dateFunded"
            value={values.dateFunded || null}
            onChange={(x) => setFieldValue('dateFunded', convertDateForPicker(x))}
            error={Boolean(touched.dateFunded && errors.dateFunded) || !isValidDate(values.dateFunded)}
            helperText={(touched.dateFunded && errors.dateFunded) || (!isValidDate(values.dateFunded) && 'MM/DD/YYYY')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled variant="outlined" fullWidth value={totalRtr} label="Total RTR" InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            type="keyboard"
            disableFuture
            label={<RequiredOption label="First Payment Date" />}
            InputLabelProps={{ shrink: true }}
            name="firstPaymentDate"
            value={values.firstPaymentDate || null}
            onChange={(x) => setFieldValue('firstPaymentDate', convertDateForPicker(x))}
            error={Boolean(touched.firstPaymentDate && errors.firstPaymentDate) || !isValidDate(values.firstPaymentDate)}
            helperText={(touched.firstPaymentDate && errors.firstPaymentDate) || (!isValidDate(values.firstPaymentDate) && 'MM/DD/YYYY')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            name="remaining"
            disabled={disableComputedFields}
            onChange={handleChange}
            value={values.remaining ?? ''}
            error={Boolean(touched.remaining && errors.remaining)}
            helperText={touched.remaining && errors.remaining}
            label={<RequiredOption label="Remaining" />}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            name="daysLeft"
            disabled={disableComputedFields}
            onChange={handleChange}
            value={values.daysLeft ?? ''}
            error={Boolean(touched.daysLeft && errors.daysLeft)}
            helperText={touched.daysLeft && errors.daysLeft}
            label={<RequiredOption label="Days Left" />}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectComponent
            name="paymentFrequency"
            label={<RequiredOption label="Deal Type" />}
            labelProps={{ shrink: true }}
            onChange={handleChange}
            fullWidth
            error={Boolean(touched.paymentFrequency && errors.paymentFrequency)}
            helperText={touched.paymentFrequency && errors.paymentFrequency}
            value={values.paymentFrequency ?? ''}
          >
            {paymentFrequency.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </SelectComponent>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            name="frequentPayment"
            onChange={handleChange}
            value={values.frequentPayment ?? ''}
            error={Boolean(touched.frequentPayment && errors.frequentPayment)}
            helperText={touched.frequentPayment && errors.frequentPayment}
            label={<RequiredOption label="Daily/Weekly payment" />}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <FileDragNDrop
            title="Contract"
            name="Contract"
            maxFiles={1}
            onDrop={(files) => setFieldValue('contract', files[0])}
            files={values.contract ? [values.contract] : []}
            uploadedFiles={values.contractUrl ? [{ id: 0, description: getPositionContractName(values.contractUrl) }] : []}
            onRemove={() => {
              setFieldValue('contract', undefined)
              setFieldValue('contractUrl', undefined)
            }}
            onRemoveUploaded={() => {
              setFieldValue('contract', undefined)
              setFieldValue('contractUrl', undefined)
            }}
          />
        </Grid>
      </Grid>
      <Box mt={6}>
        <ButtonWithPreloader loading={isLoading} type="submit" color="primary" variant="contained">
          Save
        </ButtonWithPreloader>
      </Box>
    </form>
  )
}

export default MerchantPositionsForm
