import { Box, Button, IconButton, Paper, SvgIcon, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { ReactComponent as ArrowLeft } from 'assets/svg/ArrowLeftIcon.svg'
import { useHistory, useParams } from 'react-router-dom'
import SearchField from 'UI/SearchField'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as FilterIcon } from 'assets/svg/FilterIcon.svg'
import { ReactComponent as CollapseIcon } from 'assets/svg/CollapseIcon.svg'

import SelectGroup from 'UI/Select/SelectGroup'
import { useSelectComponentStyle } from 'UI/Select'
import CustomIconButton from 'UI/Button/IconButton'
import { TableLastDealsSecondView, useFullDeals } from 'hooks/useFullDeals'
import FooterTableLatestDeals from 'components/Dashboard/FooterLatestDealsSecondDashboard'
import { usePaginationList } from 'hooks/usePaginationList'

import { useMutation, useQuery } from 'react-query'
import { getDealList, getDealStats } from 'services/deals'
import { IDealsFilters, IDealsListItem } from 'typescript/interfaces/deals'
import { DealsStatus, SortFieldsDeal } from 'typescript/enums/deals'
import RepAutoComplete from 'components/RepAutoComplete'
import ISOAutoComplete from 'components/ISOAutoComplete'
import { DASHBOARD_VIEW_FIRST } from 'constants/routes'

import { useFormik } from 'formik'
import { exportDeals } from 'services/export'
import { downloadFile } from 'utils/downloadFile'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ExportType } from 'typescript/enums/export'
import PaidStatusAutoComplete from 'components/Dashboard/PaidStatusAutoComplete'
import StatusAutoComplete from 'components/Dashboard/StatusAutoComplete'
import CalculateRestHeightBlock from 'components/CalculateRestHeightBlock'
import { UserContext } from 'contexts/userContext'
import { useLatestDealsStyle } from './style'
import { schemaLatestDealsFilters } from './schema'

const LatestDealsContainer = () => {
  const { type: pageTypeDeals } = useParams<{ type?: 'funded' | 'submitted' | 'dashboard' | 'my-deals' }>()
  const s = useLatestDealsStyle()
  const { columns } = useFullDeals({ view: pageTypeDeals || 'dashboard' })
  const { push, goBack } = useHistory()
  const selectGroupClasses = useSelectComponentStyle()
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: { roles },
  } = useContext(UserContext)

  const getStatuses = useCallback(() => {
    let dealStatuses: Array<DealsStatus> = []
    switch (pageTypeDeals) {
      case 'submitted': {
        dealStatuses = [DealsStatus.declined, DealsStatus.readyToFund, DealsStatus.new, DealsStatus.inUnderwriting, DealsStatus.outhouseApproved]
        break
      }
      case 'my-deals': {
        dealStatuses = []
        break
      }
      case 'funded': {
        dealStatuses = [DealsStatus.approved]
        break
      }
      default: {
        dealStatuses = [DealsStatus.approved]
        break
      }
    }
    return dealStatuses
  }, [pageTypeDeals])

  const { setPagination, handleFetchMore, handleSort, pagination, handleSearch } = usePaginationList<IDealsListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsDeal.id,
      sortOrder: 'DESC',
      page: 0,
      from: '',
      to: '',
      hasMore: true,
      paidStatuses: [],
      Statuses: getStatuses(),
    },
  })

  const handleFiltersSubmit = (filtersToSet: IDealsFilters) => {
    if (
      filtersToSet.repId === pagination.repId &&
      filtersToSet.isoId === pagination.isoId &&
      filtersToSet.labelId === pagination.labelId &&
      filtersToSet.from === pagination.from &&
      filtersToSet.to === pagination.to &&
      filtersToSet.paidStatuses?.toString() === pagination.paidStatuses?.toString() &&
      filtersToSet.Statuses?.toString() === pagination.Statuses?.toString()
    )
      return
    setPagination((prevState) => ({
      ...prevState,
      ...filtersToSet,
      page: 0,
      hasMore: true,
      Statuses: filtersToSet.Statuses && filtersToSet.Statuses?.length > 0 ? filtersToSet.Statuses : getStatuses(),
      data: [],
    }))
  }

  const { values: filters, setValues, handleSubmit, errors, touched, resetForm } = useFormik<IDealsFilters>({
    initialValues: {
      repId: undefined,
      isoId: undefined,
      labelId: undefined,
      from: '',
      to: '',
      paidStatuses: undefined,
      Statuses: undefined,
    },
    validationSchema: schemaLatestDealsFilters,
    onSubmit: handleFiltersSubmit,
  })

  const handleChangePaidStatuses = useCallback((e: React.ChangeEvent<{}>, values) => {
    setValues((prevState) => ({
      ...prevState,
      paidStatuses: values,
    }))
  }, [])

  const handleChangeStatuses = useCallback((e: React.ChangeEvent<{}>, values) => {
    setValues((prevState) => ({
      ...prevState,
      Statuses: values,
    }))
  }, [])

  const handleRepChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    setValues((prevState) => ({
      ...prevState,
      repId: value?.map((v: any) => v.id),
    }))
  }, [])

  const handleIsoChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    setValues((prevState) => ({
      ...prevState,
      isoId: value?.map((v: any) => v.id),
    }))
  }, [])

  const handleRangeChange = useCallback((event: any) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }, [])

  const [getDealListMut, { isLoading }] = useMutation(getDealList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const { data: dealStats } = useQuery(
    [
      'deal-stats',
      pagination.Statuses,
      pagination.paidStatuses,
      pagination.search,
      pagination.from,
      pagination.to,
      pagination.repId,
      pagination.isoId,
    ],
    () =>
      getDealStats({
        Statuses: pagination.Statuses,
        PaidStatuses: pagination.paidStatuses,
        Query: encodeURIComponent(pagination.search),
        From: pagination.from,
        To: pagination.to,
        RepresentativeId: pagination.repId,
        IsoId: pagination.isoId,
      }),
  )

  useEffect(() => {
    resetForm()
    const dealStatuses = getStatuses()
    setPagination((prevState) => ({
      ...prevState,
      data: [],
      search: '',
      order: SortFieldsDeal.id,
      sortOrder: 'DESC',
      page: 0,
      from: '',
      to: '',
      hasMore: true,
      paidStatuses: prevState.paidStatuses?.length === 0 ? prevState.paidStatuses : [],
      Statuses: dealStatuses.toString() === prevState.Statuses.toString() ? prevState.Statuses : dealStatuses,
    }))
  }, [getStatuses, resetForm])

  useEffect(() => {
    getDealListMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Query: encodeURIComponent(pagination.search),
      RepresentativeId: pagination.repId,
      IsoId: pagination.isoId,
      From: pagination.from,
      To: pagination.to,
      Statuses: pagination.Statuses?.length ? pagination.Statuses : getStatuses(),
      PaidStatuses: pagination.paidStatuses,
    })
  }, [
    pagination.page,
    pagination.order,
    pagination.sortOrder,
    pagination.search,
    pagination.repId,
    pagination.isoId,
    pagination.from,
    pagination.to,
    pagination.Statuses,
    pagination.paidStatuses,
    pagination.search,
  ])

  const [exportDealsExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportDeals, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const label = useMemo(() => {
    switch (pageTypeDeals) {
      case 'submitted': {
        return 'Submitted Deals'
      }
      case 'funded': {
        return 'Funded Deals'
      }
      case 'my-deals': {
        return 'My Deals'
      }
      default: {
        return 'Latest Deals'
      }
    }
  }, [pageTypeDeals])

  return (
    <Paper className={s.wrapper}>
      <Box p="2rem" position="relative">
        {!pageTypeDeals && (
          <Box position="absolute" top="0.5rem" right="0.5rem">
            <IconButton onClick={() => push(DASHBOARD_VIEW_FIRST)} size="small">
              <SvgIcon fontSize="small" viewBox="0 0 16 16" component={CollapseIcon} />
            </IconButton>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            {pageTypeDeals !== 'submitted' && pageTypeDeals !== 'funded' && pageTypeDeals !== 'my-deals' && (
              <Box mr="1.5rem">
                <IconButton color="secondary" onClick={() => goBack()} className={s.navButton}>
                  <ArrowLeft />
                </IconButton>
              </Box>
            )}
            <Box mb="-0.5rem">
              <Typography variant="h2" color="textSecondary">
                {label}
              </Typography>
            </Box>
          </Box>

          <Box display="flex">
            <Box maxWidth={250} mr="1.5rem">
              <SearchField handleSearch={handleSearch} name="search" label="Search the deal" fullWidth />
            </Box>
            <Button
              variant="text"
              color="secondary"
              disabled={isLoadingExportExcel}
              onClick={() =>
                exportDealsExcelMut({
                  ExportType: ExportType.excel,
                  DealStatuses: pagination.Statuses,
                })
              }
              startIcon={<SvgIcon viewBox="0 0 13 17" color="inherit" fontSize="inherit" component={ExcelIcon} />}
            >
              Export to Excel
            </Button>
          </Box>
        </Box>
        <Box p="1.5rem 1rem" className={s.roundBorder} mt="1.6rem">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box mr="1.5rem">
              <Typography color="textSecondary" noWrap>
                Filter:
              </Typography>
            </Box>
            <Box mb="-0.25rem" flex={2} ml={2} maxWidth={810} minWidth={250}>
              <SelectGroup>
                {!roles.isRep && <RepAutoComplete multiple onChange={handleRepChange} className={s.autocomplete} repId={filters.repId} />}
                {!roles.isISO && <ISOAutoComplete multiple onChange={handleIsoChange} className={s.autocomplete} isoId={filters.isoId} />}
                {pageTypeDeals === 'funded' && (
                  <PaidStatusAutoComplete
                    label="Paid Status"
                    className={s.paidStatusAutoComplete}
                    paidStatuses={filters.paidStatuses ? filters.paidStatuses : []}
                    onChange={handleChangePaidStatuses}
                  />
                )}
                {pageTypeDeals === 'submitted' && (
                  <StatusAutoComplete
                    label="Status"
                    className={s.paidStatusAutoComplete}
                    statuses={filters.Statuses ? filters.Statuses : []}
                    onChange={handleChangeStatuses}
                  />
                )}
              </SelectGroup>
            </Box>

            <Box mx="1.5rem">
              <Typography color="textSecondary" noWrap>
                Advanced Range:
              </Typography>
            </Box>
            <Box mb="-0.25rem" flex={1} ml={2} maxWidth={400} minWidth={250} display="flex">
              <TextField
                label="From"
                name="from"
                onChange={handleRangeChange}
                variant="outlined"
                fullWidth
                error={Boolean(touched.from && errors.from)}
                helperText={touched.from && errors.from}
                value={filters.from}
                className={selectGroupClasses.disabledFormControlRightBorder}
              />
              <TextField
                label="To"
                name="to"
                error={Boolean(touched.to && errors.to)}
                helperText={touched.to && errors.to}
                onChange={handleRangeChange}
                variant="outlined"
                fullWidth
                value={filters.to}
                className={selectGroupClasses.disabledFormControlLeftBorder}
              />
            </Box>
            <Box minWidth={36} width={138} ml={3}>
              <CustomIconButton onClick={() => handleSubmit()} size="small" color="secondary" width="100%" height={36}>
                <SvgIcon fontSize="inherit" viewBox="0 0 16 13" color="secondary" component={FilterIcon} />
              </CustomIconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <CalculateRestHeightBlock key={JSON.stringify(filters)}>
        <TableLastDealsSecondView
          lazy
          handleSortChange={handleSort}
          stickyHeader
          columns={columns}
          loading={isLoading}
          handleGetMore={handleFetchMore}
          data={pagination.data}
          hasMore={pagination.hasMore}
          Footer={() => <FooterTableLatestDeals stats={dealStats?.data} />}
        />
      </CalculateRestHeightBlock>
    </Paper>
  )
}

export default LatestDealsContainer
