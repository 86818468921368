import { makeStyles } from '@material-ui/core'

export const useDealInformationFormStyles = makeStyles((theme) => ({
  paper: {
    flex: 1,
    padding: '3rem',
    marginBottom: '2rem',
    '&:first-child': {
      marginRight: '1rem',
    },
    '&:nth-child(2)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '&:nth-child(3)': {
      flexBasis: '100%',
      marginBottom: 0,
    },
  },
  tableContainer: {
    marginY: theme.spacing(3),
    maxHeight: 500,
  },
  formContainer: {
    marginTop: '1.625rem',
  },
  heading: {
    fontSize: '2.25rem',
    color: theme.palette.text.secondary,
    marginBottom: '1rem',
  },
  switch: {
    marginBottom: '2.75rem',
  },
  selectLabel: {
    color: theme.palette.secondary.dark,
  },
  button: {
    marginRight: '1.25rem',
    background: theme.palette.info.light,
  },
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
  checkboxLabel: {
    color: theme.palette.secondary.dark,
    fontSize: '1.0625rem',
    marginBottom: -3,
  },
  checkboxGrid: { paddingTop: '0 !important', paddingBottom: '0 !important', display: 'flex' },
  comission: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '1.0625rem',
    marginRight: '2rem',
  },
  root: {
    width: 300,
    padding: 20,
  },
  popoverFlex: {
    width: 550,
    padding: 20,
  },
  popoverPositions: {
    width: 500,
    minWidth: 300,
    padding: 20,
  },
  popoverTextField: {
    marginTop: 20,
    marginBottom: 20,
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))
