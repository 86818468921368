import React, { FC, useState } from 'react'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { DayTypeEnum, IEarlyDiscount, earlyDiscountDescription } from 'typescript/interfaces/deals'
import { MAX_VALUE_IS, MIN_VALUE_IS, NUMBERS_ONLY } from 'constants/errors'
import * as yup from 'yup'
import SwitchExtended from 'UI/Switch/SwitchExtended'

const getOptionSchema = () =>
  yup
    .number()
    .nullable()
    .notRequired()
    .typeError(NUMBERS_ONLY)
    .min(1, MIN_VALUE_IS.replace(':value', '1'))
    .max(2, MAX_VALUE_IS.replace(':value', '2'))

const validationSchema = yup.object().shape({
  thirtyDays: getOptionSchema(),
  sixtyDays: getOptionSchema(),
  ninetyDays: getOptionSchema(),
  oneHundredTwentyDays: getOptionSchema(),
})

const defaultValues: IEarlyDiscountForm = {
  thirtyDays: undefined,
  sixtyDays: undefined,
  ninetyDays: undefined,
  oneHundredTwentyDays: undefined,
}

interface IEarlyDiscountForm {
  thirtyDays?: number,
  sixtyDays?: number,
  ninetyDays?: number,
  oneHundredTwentyDays?: number,
}

interface EarlyDiscountFormProps {
  initialValues?: IEarlyDiscount
  onSubmit: (values?: IEarlyDiscount) => void
  classes: any
}

const EarlyDiscountForm: FC<EarlyDiscountFormProps> = ({ initialValues, onSubmit, ...props }) => {
  const [dayType, setDayType] = useState<DayTypeEnum>(initialValues?.dayType ?? DayTypeEnum.calendar);

  let initialFormValues: IEarlyDiscountForm = defaultValues

  if (initialValues) {
    for (const [key, value] of Object.entries(initialValues)) {
      const newKey = key as keyof IEarlyDiscountForm;

      initialFormValues = {
        ...initialFormValues,
        [newKey]: value
      }
    }
  }
  
  const { values, errors, touched, handleChange, handleSubmit } = useFormik<IEarlyDiscountForm>({
    validationSchema,
    initialValues: initialFormValues,
    enableReinitialize: false,
    onSubmit: (formValues) => {
      const transformedValues: any = Object.fromEntries(Object.entries(formValues).map(([key, value]) => [key, value === '' ? null : value]))
      if (Object.values(transformedValues).every((x) => !x)) {
        onSubmit(undefined);

        return;
      }

      const result : IEarlyDiscount = {
        ...transformedValues,
        dayType
      };

      onSubmit(result)
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        <Typography variant="body1">Early discounts</Typography>
      </Box>

      {dayType === DayTypeEnum.calendar
        ? <SwitchExtended
          key={'calendar'}
          variants={[
            { title: 'Calendar Days', value: 'calendar' },
            { title: 'Business Days', value: 'business' },
          ]}
          value="calendar"
        onClick={() => {
          setDayType(DayTypeEnum.business)
        }
        }
      />
        : <SwitchExtended
          key={'business'}
          variants={[
            { title: 'Calendar Days', value: 'calendar' },
            { title: 'Business Days', value: 'business' },
          ]}
          value="business"
        onClick={() => {
          setDayType(DayTypeEnum.calendar)
        }
        }
      />
      }

      <Grid container style={{ marginTop: '1.5rem' }}>
        {Object.entries(values)
        .filter(e => e[0] in defaultValues)
        .map((entry) => entry as [keyof IEarlyDiscountForm, number?])
        .map(([key, factor]) => (
          <TextField
            {...props}
            fullWidth
            variant="outlined"
            placeholder="Factor rate"
            InputLabelProps={{ shrink: true }}
            name={key}
            value={factor ?? ''}
            onChange={handleChange}
            label={earlyDiscountDescription[key]}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
          />
        ))}
      </Grid>
      
      <Button type="submit" color="primary" variant="contained" fullWidth>
        Save
      </Button>
    </form>
  )
}

export default EarlyDiscountForm
