import Axios, { AxiosResponse } from 'axios'
import {
  API_CHANGE_DEALS_STATUS,
  API_CONNECT_DEAL_DOCUMENTS_TO_DEAL,
  API_CREATE_INTHOUSE_DEAL,
  API_CREATE_OUTHOUSE_DEAL,
  API_DELETE_DEAL,
  API_DELETE_DEAL_DOCUMENT,
  API_EDIT_INTHOUSE_DEAL,
  API_EDIT_OUTHOUSE_DEAL,
  API_GET_DEAL,
  API_GET_DEALS_LIST,
  API_GET_DEALS_QUES,
  API_SET_LENDER,
  API_EDIT_LENDER,
  API_GET_LENDERS_LIST,
  API_GET_DEAL_LIGHT_LIST,
  API_GET_UPLOAD_URL,
  API_CREATE_SCHEDULE,
  API_DELETE_LENDER,
  API_GET_DEALS_STATS,
  API_GET_DEALS_STATS_FOR_CHARTS,
  API_GET_AUDIT_LOG,
  API_CREATE_BASE_DEAL,
  API_EDIT_BASE_DEAL,
  API_GET_COMMENTS_LOG,
  API_CREATE_COMMENT_LOG,
  API_GET_REP_PORTFOLIO_SUMMARY,
  API_SET_TO_DEFAULT,
  API_STOP_CLIENT_FEES,
  API_START_CLIENT_FEES,
  API_REFUND_DEAL,
  API_REFI_DEAL,
  API_SEND_TRANSACTION_HISTORY_TO_EMAIL,
  API_GET_SCHEDULES_BY_DEAL,
  API_PREP_FUNDING_EMAIL,
  API_READY_TO_FUND_EMAIL,
  API_SUBMIT_DEAL,
  API_PAY_OFF,
  API_DELETE_ALL_SCHEDULE,
  API_DELETE_SCHEDULE,
  API_GET_FUNDER_LENDERS,
  API_EXTEND_DEAL,
  API_CLAWBACK,
  API_MOVE_TO_DOCUSIGN,
  API_GET_REFI_DEALS,
  API_ISO_CLAWBACK,
  API_ZERO_BALANCE,
  API_CONNECT_CLIENT_DOCUMENTS_TO_DEAL,
  API_RETURN_ISO_CLAWBACK,
  API_RETURN_CLAWBACK,
  API_DEAL_UPDATE_MERCHANT_CLIENTS,
  API_DEAL_GET_MERCHANT_CLIENTS,
  API_GET_REVERSE_SCHEDULES_BY_DEAL,
  API_DELETE_ALL_REVERSE_SCHEDULE,
  API_DELETE_REVERSE_SCHEDULE,
  API_CALCULATE_REVERSE_SCHEDULE,
  API_GET_FLEX_SCHEDULE_BY_DEAL,
  API_DELETE_FLEX_DISBURSEMENT,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { CompanyType } from 'typescript/interfaces/companies'
import {
  ICreateUpdateInhouseDeal,
  ICreateUpdateOuthouseDeal,
  IInhouseDealResponse,
  IQueItem,
  AdditionalPaginationProps,
  IEditStatusParam,
  ILenderEntity,
  ILenderParams,
  IGetDealsLight,
  ILightDeal,
  IDealDocumentUploadLinkParams,
  IDealDocumentConnectParams,
  IDealDocumentConnectParamsResponse,
  IUploadDocWithDocType,
  ISchedulePaymentInitValues,
  IDealsListItem,
  IDealsStats,
  IAuditLogListItem,
  IDealBaseValues,
  IStatisticWithChartsResponse,
  ICommentLogItem,
  ICreateCommentLog,
  IRepPortfolioSummaryParams,
  IRepPortfolioSummaryResponse,
  ISetToDefaultParams,
  IRefundDealParams,
  IScheduleListItem,
  IFunderLenderItem,
  IExtendDealParams,
  IMerchantClientsResponse,
  IMerchantClientsUpdate,
  IReverseScheduleListItem,
  IFlexDisbursement,
  IMerchantPosition,
} from 'typescript/interfaces/deals'
import removeEmptyAttributes from 'utils/removeEmptyAttributes'
import { IPagination } from 'typescript/interfaces/pagination'
import { SortFieldsDeal } from 'typescript/enums/deals'
import { generatePath } from 'react-router-dom'
import { storageInstance } from 'services/axios/storageInstance'
import { PaymentFrequencyEnum } from 'typescript/interfaces/paymentFrequency'

export const transformRequestOptions = (params: any) => {
  let options = ''
  for (const key in params) {
    if (typeof params[key] !== 'object' && (params[key] || params[key] === 0)) {
      options += `${key}=${params[key]}&`
    } else if (typeof params[key] === 'object' && (params[key] || params[key] === 0) && params[key].length) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      params[key].forEach((el) => {
        options += `${key}=${el}&`
      })
    }
  }
  return options ? options.slice(0, -1) : options
}

export const createOuthouseDeal = (values: ICreateUpdateOuthouseDeal) =>
  privateInstance.post<ICreateUpdateOuthouseDeal, AxiosResponse<ICreateUpdateOuthouseDeal>>(
    API_CREATE_OUTHOUSE_DEAL,
    removeEmptyAttributes({
      ...values,
      companyType: CompanyType.OutHouse,
    }),
  )

export const editOuthouseDeal = (values: ICreateUpdateOuthouseDeal) =>
  privateInstance.put<ICreateUpdateOuthouseDeal, AxiosResponse<ICreateUpdateOuthouseDeal>>(
    API_EDIT_OUTHOUSE_DEAL,
    removeEmptyAttributes({
      ...values,
      companyType: CompanyType.OutHouse,
      advanceAmount: +values.advanceAmount!,
    }),
  )

export const createInhouseDeal = async (values: ICreateUpdateInhouseDeal) => {
  const createDealResponse = await privateInstance.post<ICreateUpdateInhouseDeal, AxiosResponse<ICreateUpdateInhouseDeal>>(
    API_CREATE_INTHOUSE_DEAL,
    removeEmptyAttributes({
      ...values,
      companyType: CompanyType.InHouse,
      advanceAmount: +values.advanceAmount!,
    }),
  )

  await storageInstance.post(API_CONNECT_CLIENT_DOCUMENTS_TO_DEAL.replace(':id', createDealResponse.data.id?.toString() ?? ''), null)

  return createDealResponse
}

export const editInhouseDeal = (values: ICreateUpdateInhouseDeal) =>
  privateInstance.put<ICreateUpdateInhouseDeal, AxiosResponse<ICreateUpdateInhouseDeal>>(
    API_EDIT_INTHOUSE_DEAL,
    removeEmptyAttributes({
      ...values,
      psfFee: Number(values.psfFee),
      syndicationFee: Number(values.syndicationFee),
      payOffAmount: Number(values.payOffAmount),
      isoFee: Number(values.isoFee),
      commissionToRep: Number(values.commissionToRep),
      companyType: CompanyType.InHouse,
      advanceAmount: +values.advanceAmount!,
    }),
  )

export const getDeal = (id: string) => privateInstance.get<IInhouseDealResponse, AxiosResponse<IInhouseDealResponse>>(API_GET_DEAL.replace(':id', id))

export const deleteDeal = (id: string) => privateInstance.delete(API_DELETE_DEAL.replace(':id', id))

export const getDealList = (params: IPagination<SortFieldsDeal> & AdditionalPaginationProps) =>
  privateInstance.get<IPagination<SortFieldsDeal> & AdditionalPaginationProps, AxiosResponse<Array<IDealsListItem>>>(API_GET_DEALS_LIST, {
    params: params,
    paramsSerializer: transformRequestOptions,
  })

export const getDealStats = (params: IPagination<SortFieldsDeal> & AdditionalPaginationProps) =>
  privateInstance.get<IPagination & AdditionalPaginationProps, AxiosResponse<IDealsStats>>(API_GET_DEALS_STATS, {
    params,
    paramsSerializer: transformRequestOptions,
  })

export const getDealStatsForChart = (params: IPagination<SortFieldsDeal> & AdditionalPaginationProps) =>
  privateInstance.get<IPagination<SortFieldsDeal> & AdditionalPaginationProps, AxiosResponse<IStatisticWithChartsResponse>>(
    API_GET_DEALS_STATS_FOR_CHARTS,
    {
      params: params,
    },
  )

export const getMerchantClients = (dealId: string) =>
  privateInstance.get<IMerchantClientsResponse, AxiosResponse<IMerchantClientsResponse>>(API_DEAL_GET_MERCHANT_CLIENTS.replace(':dealId', dealId))

export const updateMerchantClients = (request: IMerchantClientsUpdate) =>
  privateInstance.put<IMerchantClientsResponse, AxiosResponse<IMerchantClientsResponse>>(API_DEAL_UPDATE_MERCHANT_CLIENTS, request)

export const getFundingQues = () => privateInstance.get<unknown, AxiosResponse<IQueItem[]>>(API_GET_DEALS_QUES)

export const changeDealStatus = (params: IEditStatusParam) =>
  privateInstance.put<IEditStatusParam, AxiosResponse<boolean>>(API_CHANGE_DEALS_STATUS, params)

export const createLender = (values: ILenderParams) => privateInstance.post<ILenderParams, AxiosResponse<ILenderEntity>>(API_SET_LENDER, values)

export const editLender = (values: ILenderParams) => privateInstance.put<ILenderParams, AxiosResponse<ILenderEntity>>(API_EDIT_LENDER, values)

export const deleteLender = (values: { companyId: string | number; lenderId: string | number }) =>
  privateInstance.delete<any, AxiosResponse<boolean>>(
    generatePath(API_DELETE_LENDER, {
      companyId: values.companyId,
      lenderId: values.lenderId,
    }),
  )

export const getLenderList = (dealId: number | string) =>
  privateInstance.get<typeof dealId, AxiosResponse<Array<ILenderEntity>>>(
    generatePath(API_GET_LENDERS_LIST, {
      id: dealId,
    }),
    {
      params: {
        dealId,
      },
    },
  )

export const getDealLight = (params: IGetDealsLight) =>
  privateInstance.get<IGetDealsLight, AxiosResponse<Array<ILightDeal>>>(API_GET_DEAL_LIGHT_LIST, {
    params: params,
    paramsSerializer: transformRequestOptions,
  })

export const refiDeal = (params: Array<number> | Array<string>) =>
  privateInstance.post<Array<number> | Array<string>, AxiosResponse<IInhouseDealResponse>>(API_REFI_DEAL, params)

export const getRefiDeals = (id: string | number) =>
  privateInstance.get<typeof id, AxiosResponse<Array<ILightDeal>>>(
    generatePath(API_GET_REFI_DEALS, {
      clientId: id,
    }),
  )

export const getDealDownloadUrl = (params: IDealDocumentUploadLinkParams) =>
  storageInstance.get<string, AxiosResponse<string>>(API_GET_UPLOAD_URL, { params })

export const uploadDealDocumentToS3 = async (file: File, link: string) => {
  const buffer = await file.arrayBuffer()
  const response = await Axios.put<File, AxiosResponse<string>>(link, buffer, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

export const connectUploadedUrlToDeal = async (body: IDealDocumentConnectParams) =>
  storageInstance.post<IDealDocumentConnectParams, AxiosResponse<IDealDocumentConnectParamsResponse>>(API_CONNECT_DEAL_DOCUMENTS_TO_DEAL, body)

export const uploadDocuments = async (documents: IUploadDocWithDocType[], dealId: number) => {
  const links = await Promise.all(
    documents.map((doc) => getDealDownloadUrl({ DocumentName: doc.file.name, DocumentDestination: 'Deal', DestinationObjectId: dealId })),
  )

  const uploadedFilesLinks = await Promise.all(links.map((link, i) => uploadDealDocumentToS3(documents[i].file, link.data)))

  const connectedUploadedUrls = await Promise.all(
    uploadedFilesLinks.map(
      (uploadedLink, i) =>
        connectUploadedUrlToDeal({
          documentUrl: links[i].data.split('?')[0],
          description: documents[i].file.name,
          dealId,
          documentType: documents[i].documentType,
        }),
      //eslint-disable-next-line
    ),
  )

  return connectedUploadedUrls
}

export const deleteFileFromDeal = (id: string) => storageInstance.delete(API_DELETE_DEAL_DOCUMENT.replace(':id', id))

export const createSchedule = (values: Partial<ISchedulePaymentInitValues>) =>
  privateInstance.post<typeof values, AxiosResponse<boolean>>(API_CREATE_SCHEDULE, values)

export const getAuditLog = (params: IPagination & { DealId: string }) =>
  privateInstance.get<IPagination, AxiosResponse<Array<IAuditLogListItem>>>(API_GET_AUDIT_LOG, { params })

export const getCommentsLog = (params: IPagination & { DealId: string }) =>
  privateInstance.get<IPagination, AxiosResponse<Array<ICommentLogItem>>>(API_GET_COMMENTS_LOG, { params })

export const newCommentLog = (values: ICreateCommentLog) =>
  privateInstance.post<ICreateCommentLog, AxiosResponse<ICommentLogItem>>(API_CREATE_COMMENT_LOG, values)

export const createBaseDeal = (values: IDealBaseValues) =>
  privateInstance.post<ICreateUpdateInhouseDeal, AxiosResponse<IDealBaseValues>>(
    API_CREATE_BASE_DEAL,
    removeEmptyAttributes({
      ...values,
    }),
  )

export const editBaseDeal = (values: IDealBaseValues) =>
  privateInstance.put<IDealBaseValues, AxiosResponse<IDealBaseValues>>(
    API_EDIT_BASE_DEAL,
    removeEmptyAttributes({
      ...values,
    }),
  )

export const getRepPortfolioSummary = (params: IRepPortfolioSummaryParams) =>
  privateInstance.get<IRepPortfolioSummaryParams, AxiosResponse<IRepPortfolioSummaryResponse>>(API_GET_REP_PORTFOLIO_SUMMARY, {
    params: params,
    paramsSerializer: transformRequestOptions,
  })

export const setToDefault = (values: ISetToDefaultParams) =>
  privateInstance.post<ISetToDefaultParams, AxiosResponse<boolean>>(API_SET_TO_DEFAULT, values)

export const stopClientFees = (id: number | string) =>
  privateInstance.post<number | string, AxiosResponse<boolean>>(
    generatePath(API_STOP_CLIENT_FEES, {
      id: id,
    }),
  )

export const startClientFees = (id: number | string) =>
  privateInstance.post<number | string, AxiosResponse<boolean>>(
    generatePath(API_START_CLIENT_FEES, {
      id: id,
    }),
  )

export const refundDeal = (values: IRefundDealParams) => privateInstance.post<IRefundDealParams, AxiosResponse<boolean>>(API_REFUND_DEAL, values)

export const extendDeal = (values: IExtendDealParams) => privateInstance.post<IExtendDealParams, AxiosResponse<boolean>>(API_EXTEND_DEAL, values)

export const sendPayHistoryToEmail = (dealId: string | number) =>
  privateInstance.post<string | number, AxiosResponse<boolean>>(
    generatePath(API_SEND_TRANSACTION_HISTORY_TO_EMAIL, {
      dealId: dealId,
    }),
  )

export const getSchedulesByDeal = (dealId: string | number) =>
  privateInstance.get<string | number, AxiosResponse<IScheduleListItem[]>>(
    generatePath(API_GET_SCHEDULES_BY_DEAL, {
      dealId: dealId,
    }),
  )

export const sendPrepFundingEmail = (dealId: string | number) =>
  privateInstance.post<string | number, AxiosResponse<boolean>>(
    generatePath(API_PREP_FUNDING_EMAIL, {
      dealId: dealId,
    }),
  )

export const sendReadyToFundEmail = (dealId: string | number) =>
  privateInstance.post<string | number, AxiosResponse<boolean>>(
    generatePath(API_READY_TO_FUND_EMAIL, {
      dealId: dealId,
    }),
  )

export const submitDeal = (id: string | number) =>
  privateInstance.put<string | number, AxiosResponse<boolean>>(
    API_SUBMIT_DEAL,
    { id: id },
    {
      params: {
        id: id,
      },
    },
  )

export const emailPayOffLetter = (id: string | number) =>
  privateInstance.post<typeof id, AxiosResponse<boolean>>(
    generatePath(API_PAY_OFF, {
      id: id,
    }),
  )

export const zeroBalanceLetter = (id: string | number) =>
  privateInstance.post<typeof id, AxiosResponse<boolean>>(
    generatePath(API_ZERO_BALANCE, {
      id: id,
    }),
  )

export const deleteAllSchedules = (id: string | number) =>
  privateInstance.delete<typeof id, AxiosResponse<boolean>>(
    generatePath(API_DELETE_ALL_SCHEDULE, {
      dealId: id,
    }),
  )

export const deleteSchedule = (id: string | number) =>
  privateInstance.delete<typeof id, AxiosResponse<boolean>>(
    generatePath(API_DELETE_SCHEDULE, {
      scheduleId: id,
    }),
  )

export const getReverseSchedulesByDeal = (dealId: string | number) =>
  privateInstance.get<string | number, AxiosResponse<IReverseScheduleListItem[]>>(
    generatePath(API_GET_REVERSE_SCHEDULES_BY_DEAL, {
      dealId: dealId,
    }),
  )

export const getFlexScheduleByDeal = (dealId: string | number) =>
  privateInstance.get<string | number, AxiosResponse<IFlexDisbursement[]>>(
    generatePath(API_GET_FLEX_SCHEDULE_BY_DEAL, {
      dealId: dealId,
    }),
  )

export const deleteReverseSchedule = (id: string | number) =>
  privateInstance.delete<typeof id, AxiosResponse<boolean>>(
    generatePath(API_DELETE_REVERSE_SCHEDULE, {
      scheduleId: id,
    }),
  )

export const deleteAllReverseSchedules = (id: string | number) =>
  privateInstance.delete<typeof id, AxiosResponse<boolean>>(
    generatePath(API_DELETE_ALL_REVERSE_SCHEDULE, {
      dealId: id,
    }),
  )

export const deleteFlexDisbursement = (disbursementId: string | number) =>
  privateInstance.delete<typeof disbursementId, AxiosResponse<boolean>>(
    generatePath(API_DELETE_FLEX_DISBURSEMENT, {
      disbursementId,
    }),
  )

export const calculateReverseSchedule = (disbursementFrequency: PaymentFrequencyEnum, positions: IMerchantPosition[]) =>
  privateInstance.post<typeof positions, AxiosResponse<IFlexDisbursement[]>>(API_CALCULATE_REVERSE_SCHEDULE, positions, {
    params: { disbursementFrequency },
  })

export const getFunderLenders = (id: string | number) =>
  privateInstance.get<typeof id, AxiosResponse<IFunderLenderItem[]>>(
    generatePath(API_GET_FUNDER_LENDERS, {
      dealId: id,
    }),
  )

export const isoClawback = (dealId: string | number) =>
  privateInstance.post<string | number, AxiosResponse<boolean>>(
    generatePath(API_ISO_CLAWBACK, {
      dealId: dealId,
    }),
  )

export const returnIsoClawback = (dealId: string | number) =>
  privateInstance.post<string | number, AxiosResponse<boolean>>(
    generatePath(API_RETURN_ISO_CLAWBACK, {
      dealId: dealId,
    }),
  )

export const repClawback = (dealId: string | number) =>
  privateInstance.post<string | number, AxiosResponse<boolean>>(
    generatePath(API_CLAWBACK, {
      dealId: dealId,
    }),
  )

export const returnRepClawback = (dealId: string | number) =>
  privateInstance.post<string | number, AxiosResponse<boolean>>(
    generatePath(API_RETURN_CLAWBACK, {
      dealId: dealId,
    }),
  )

export const moveToDocusign = (id: string | number) => privateInstance.get(API_MOVE_TO_DOCUSIGN.replace(':id', id.toString()))
