import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import { ITransactionListItem, ITransactionParamsInitValue, ITransactionsQueryParams } from 'typescript/interfaces/transactions'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as DocumentIcon } from 'assets/svg/DocumentOutlined.svg'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import Paper from '@material-ui/core/Paper'
import { useTransactionsStyle } from 'containers/Transactions/style'
import cn from 'classnames'
import { convertToPriceFormat } from 'utils/formatters'
import SearchField from 'UI/SearchField'
import Checkbox from 'UI/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { TableTransactions, useTransactions } from 'hooks/useTransactions'
import ModalComponent from 'UI/Modal'
import AddTransactionForm from 'components/AddTransactionForm'
import IconButton from '@material-ui/core/IconButton'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import {
  ISO_INFO_URL,
  ISO_LIST_URL,
  REP_INFO_URL,
  REP_LIST_URL,
  SYNDICATOR_INFO_URL,
  SYNDICATORS_URL,
  VENDOR_INFO_URL,
  VENDOR_LIST_URL,
} from 'constants/routes'
import { useMutation, useQuery } from 'react-query'
import { createTransaction, getLeverage, getTransactionList } from 'services/transactions'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { getIsoById } from 'services/iso'
import { usePaginationList } from 'hooks/usePaginationList'
import { SortFieldsTransactionsList, StatusPayment } from 'typescript/enums/transactions'
import { getRepById } from 'services/rep'
import { getVendorById } from 'services/vendor'
import { getSyndicator } from 'services/syndicators'
import { exportTransactions } from 'services/export'
import { downloadFile } from 'utils/downloadFile'
import { ExportType } from 'typescript/enums/export'
import Button from 'UI/Button/ButtonWithPreloader'
import { ADD_TRANSACTION_PERM, TRANSACTION_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'
import { UserContext } from 'contexts/userContext'
import { AxiosError } from 'axios'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const TransactionsContainer: FC = () => {
  const {
    state: { roles },
  } = useContext(UserContext)
  const { goBack } = useHistory()
  const { hasPermission, isOwnVendor, isOwnRep, isOwnSyndicator, isOwnIso } = usePermission()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useTransactionsStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const { columns } = useTransactions()
  const [openTrsModal, setOpenTrsModal] = useState(false)
  const { type, id, businessName } = useParams<ITransactionsQueryParams>()
  const { handleSort, handleFetchMore, pagination, setPagination, handleSearch } = usePaginationList<ITransactionListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsTransactionsList.date,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const { data: dataIso, isLoading: isLoadingGetIso } = useQuery(['isoById', id], () => getIsoById(id), {
    retry: false,
    enabled: type === 'iso',
  })

  const { data: dataRep, isLoading: isLoadingGetReps } = useQuery(['repById', id], () => getRepById(id), {
    retry: false,
    enabled: type === 'rep',
  })

  const { data: dataVendor, isLoading: isLoadingGetVendors } = useQuery(['vendorById', id], () => getVendorById(id), {
    retry: false,
    enabled: type === 'vendor',
  })

  const { data: dataSyndicator, isLoading: isLoadingGetSyn } = useQuery(['syndicatorById', id], () => getSyndicator(id), {
    retry: false,
    enabled: type === 'syndicator',
  })

  const [createTransMut, { isLoading }] = useMutation(createTransaction, {
    onSuccess: () => {
      enqueueSnackbar('Transaction successfully created')
      setOpenTrsModal(false)
      if (pagination.page === 0)
        getTransactions(
          {
            PageSize: 20,
            PageIndex: pagination.page,
            Query: pagination.search,
            SortField: pagination.order,
            SortOrder: pagination.sortOrder,
            UserPageId: userPageId || '',
          },
          {
            onSuccess: (data) => {
              setPagination((prevState) => ({
                ...prevState,
                page: 0,
                data: [...data.data.transactions],
                hasMore: data.data.transactions.length === 20,
                currentBalance: data.data.currentBalance,
              }))
            },
          },
        )
      else
        setPagination((prevState) => ({
          ...prevState,
          page: 0,
          data: [],
          hasMore: true,
        }))
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'WITHDRAWAL_LIMIT_EXCEEDED') enqueueSnackbar(`Can't be more than 2 withdrawals per month`)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const getUrls = useCallback(() => {
    let listUrl = ''
    let infoUrl = ''
    let listLabel = ''
    switch (type) {
      case 'iso': {
        listLabel = 'ISO list'
        listUrl = ISO_LIST_URL
        infoUrl = generatePath(ISO_INFO_URL, {
          id: id,
        })
        break
      }
      case 'rep': {
        listLabel = 'REP list'
        listUrl = REP_LIST_URL
        infoUrl = generatePath(REP_INFO_URL, {
          id: id,
        })
        break
      }
      case 'vendor': {
        listLabel = 'Vendor list'
        listUrl = VENDOR_LIST_URL
        infoUrl = generatePath(VENDOR_INFO_URL, {
          id: id,
        })
        break
      }
      case 'syndicator': {
        listLabel = 'Syndicator list'
        listUrl = SYNDICATORS_URL
        infoUrl = generatePath(SYNDICATOR_INFO_URL, {
          id: id,
        })
        break
      }
      default: {
        break
      }
    }
    return {
      listUrl,
      infoUrl,
      listLabel,
    }
  }, [type, id])

  const handleCreateTrans = useCallback(
    (values: Partial<ITransactionParamsInitValue>) => {
      const copyValues = { ...values }
      delete copyValues.userId
      if (!copyValues.dealId) copyValues.dealId = null
      if (copyValues.category === 'Withdrawal') copyValues.status = StatusPayment.scheduled
      createTransMut(copyValues)
    },
    [createTransMut],
  )

  const userPageId = useMemo(() => {
    switch (type) {
      case 'iso': {
        return dataIso ? dataIso.data.userPage.id?.toString() : '0'
      }
      case 'rep': {
        return dataRep ? dataRep.data.userPage.id?.toString() : '0'
      }
      case 'vendor': {
        return dataVendor ? dataVendor.data.userPage.id.toString() : '0'
      }
      case 'syndicator': {
        return dataSyndicator ? dataSyndicator.data.userPage.id?.toString() : ''
      }
      default: {
        return ''
      }
    }
  }, [dataIso, type, dataRep, dataVendor, dataSyndicator])

  const balance = useMemo(() => {
    switch (type) {
      case 'iso': {
        return dataIso ? +dataIso.data.userPage.availableBalance : 0
      }
      case 'rep': {
        return dataRep ? +dataRep.data.userPage.availableBalance : 0
      }
      case 'vendor': {
        return dataVendor ? +dataVendor.data.userPage.availableBalance : 0
      }
      case 'syndicator': {
        return dataSyndicator ? +dataSyndicator.data.userPage.availableBalance : 0
      }
      default: {
        return 0
      }
    }
  }, [dataIso, type, dataRep, dataVendor, dataSyndicator])

  const { data: dataLeverage, isLoading: isLoadingLeverage } = useQuery(['get-leverage', userPageId], () => getLeverage(userPageId ?? ''), {
    enabled: Boolean(dataSyndicator && userPageId),
  })

  const [getTransactions, { isLoading: isLoadingListTransactions }] = useMutation(getTransactionList)

  useEffect(() => {
    if (userPageId && userPageId !== '0')
      getTransactions(
        {
          PageSize: 20,
          PageIndex: pagination.page,
          Query: pagination.search,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
          UserPageId: userPageId,
        },
        {
          onSuccess: (data) => {
            setPagination((prevState) => ({
              ...prevState,
              data: [...prevState.data, ...data.data.transactions],
              hasMore: data.data.transactions.length === 20,
              currentBalance: data.data.currentBalance,
            }))
          },
        },
      )
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, userPageId])

  const [exportTransactionsExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportTransactions, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  if (
    !hasPermission(TRANSACTION_PERM) &&
    !(type === 'vendor' && isOwnVendor(+id)) &&
    !(type === 'rep' && isOwnRep(+id)) &&
    !(type === 'syndicator' && isOwnSyndicator(+id)) &&
    !(type === 'iso' && isOwnIso(+id))
  )
    goBack()

  return (
    <Box>
      <Box>
        <Breadcrumbs
          separator=">"
          classes={{
            separator: classes.separator,
          }}
        >
          {roles.isAdmin && (
            <Link className={cn(classes.linkBreadCrumb)} to={getUrls().listUrl}>
              {getUrls().listLabel}
            </Link>
          )}
          <Link className={cn(classes.linkBreadCrumb)} to={getUrls().infoUrl}>
            # {id}
          </Link>
          <Typography color="textSecondary">Activity</Typography>
        </Breadcrumbs>
      </Box>
      <Box display="flex" alignItems="center" mt="1rem">
        <Box flex={1}>
          <Typography variant="h2" color="textSecondary">
            Transactions
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            color="inherit"
            variant="contained"
            startIcon={<PlusIcon />}
            onClick={() => setOpenTrsModal(true)}
            disabled={!hasPermission(ADD_TRANSACTION_PERM)}
          >
            Add New Record
          </Button>
          <Box ml="0.75rem">
            <Button
              color="inherit"
              variant="contained"
              loading={isLoadingExportExcel}
              onClick={() =>
                exportTransactionsExcelMut({
                  ExportType: ExportType.excel,
                  UserPageId: userPageId,
                })
              }
              startIcon={<ExcelIcon />}
            >
              Export To Excel
            </Button>
          </Box>
          <Box ml="0.75rem">
            <Button
              color="inherit"
              onClick={() =>
                exportTransactionsExcelMut({
                  ExportType: ExportType.pdf,
                  UserPageId: userPageId,
                })
              }
              loading={isLoadingExportExcel}
              variant="contained"
              startIcon={<DocumentIcon />}
            >
              Download as PDF
            </Button>
          </Box>
        </Box>
      </Box>
      <Box mt="0.75rem">
        <Paper className={additionalStyle.wrapper} elevation={0}>
          <Box py="2rem">
            <Box px="2rem">
              <Box display="flex" alignItems="flex-end">
                <Box>
                  {pagination.currentBalance !== undefined && (
                    <>
                      <Typography gutterBottom className={cn(classes.secondaryDarkTypography)}>
                        Current Balance
                      </Typography>
                      <Typography color="textSecondary" variant="h3">
                        {convertToPriceFormat(pagination.currentBalance || balance)}
                      </Typography>
                    </>
                  )}
                </Box>
                {Boolean(pagination.currentBalance !== undefined && dataLeverage && !isLoadingLeverage) && (
                  <>
                    <Box ml={8}>
                      <Typography gutterBottom className={cn(classes.secondaryDarkTypography)}>
                        Leverage
                      </Typography>
                      <Typography color="textSecondary" variant="h3">
                        {convertToPriceFormat(dataLeverage?.data)}
                      </Typography>
                    </Box>
                    <Box ml={8}>
                      <Typography gutterBottom className={cn(classes.secondaryDarkTypography)}>
                        Net Balance
                      </Typography>
                      <Typography color="textSecondary" variant="h3">
                        {convertToPriceFormat(pagination.currentBalance - dataLeverage.data)}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box flex={1} ml="2rem">
                  <Box width="100%" maxWidth={500}>
                    <SearchField label="Search the transaction" handleSearch={(search) => handleSearch(search)} fullWidth />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" ml="0.5rem">
                  <FormControlLabel
                    control={<Checkbox name="isMailingAddressDifferent" defaultChecked color="primary" />}
                    label={<Typography color="textSecondary">Reconciled</Typography>}
                  />
                  <Box ml="1rem">
                    <FormControlLabel
                      control={<Checkbox name="isMailingAddressDifferent" defaultChecked color="primary" />}
                      label={<Typography color="textSecondary">Not reconciled</Typography>}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mt="1rem">
              <TableTransactions
                hasMore={pagination.hasMore}
                handleSortChange={handleSort}
                handleGetMore={handleFetchMore}
                columns={columns}
                data={pagination.data}
                loading={isLoadingGetIso || isLoadingListTransactions || isLoadingGetReps || isLoadingGetVendors || isLoadingGetSyn}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
      {userPageId && (
        <ModalComponent open={openTrsModal}>
          <Box maxWidth={500}>
            <Box display="flex" justifyContent="flex-end" mt="-1rem" mr="-1rem">
              <IconButton
                color="secondary"
                size="small"
                onClick={() => setOpenTrsModal(false)}
                classes={{
                  root: cn(classes.closeTrsModalButton),
                }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
            <Typography variant="h2" color="textSecondary">
              {businessName}
            </Typography>
            <Box mt="2rem">
              <AddTransactionForm
                userPageId={userPageId}
                loading={isLoading}
                submit={handleCreateTrans}
                handleCancel={() => setOpenTrsModal(false)}
              />
            </Box>
          </Box>
        </ModalComponent>
      )}
    </Box>
  )
}

export default TransactionsContainer
