import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { getStates } from 'services/states'

const statesCache = new Map<number, string>()

const useStateAbbreviation = (stateId?: number) => {
  const { data, isLoading } = useQuery('states', getStates, { enabled: stateId && !statesCache.size })

  useEffect(() => {
    if (data && !statesCache.size) {
      data.data.forEach((state) => statesCache.set(state.id, state.abbreviation))
    }
  }, [data])

  const result = stateId ? statesCache.get(stateId) : undefined

  return [result, isLoading] as const
}

export default useStateAbbreviation
