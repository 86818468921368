import { useState, useCallback } from 'react'

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const open = useCallback((event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget || event.target), [])
  const close = useCallback(() => setAnchorEl(null), [])
  const reposition = useCallback(() => {
    const anchor = anchorEl
    setAnchorEl(null)
    setTimeout(() => setAnchorEl(anchor), 0)
  }, [anchorEl])

  return { anchorEl, isOpen, open, close, reposition }
}

export default usePopover
