import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { IUserPage } from 'typescript/interfaces/users'

interface IUserPageInfoProps {
  userPage: IUserPage
  nameOnCheck?: string
  disableCurrBalance?: boolean
}

const UserPageInfo: FC<IUserPageInfoProps> = ({ userPage, nameOnCheck, disableCurrBalance }) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={6}>
        <Typography>ACH Bank Routing #</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color="textSecondary">{convertEmptyField(userPage.bankRouting)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Wire Bank Routing #</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color="textSecondary">{convertEmptyField(userPage.bankRoutingWire)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Bank Account Name</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color="textSecondary">{convertEmptyField(userPage.bankAccountName)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Bank Account #</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color="textSecondary">{convertEmptyField(userPage.bankAccount)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Bank Name</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color="textSecondary">{convertEmptyField(userPage.bankName)}</Typography>
      </Grid>
      {!disableCurrBalance && (
        <>
          <Grid item xs={6}>
            <Typography>Current Balance</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">{convertToPriceFormat(userPage.availableBalance)}</Typography>
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <Typography>Name on Voided Check</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color="textSecondary">{convertEmptyField(nameOnCheck)}</Typography>
      </Grid>
    </Grid>
  )
}

export default UserPageInfo
