import { useState } from 'react'
import useDebouncedEffect from './useDebouncedEffect'

const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useDebouncedEffect(() => setDebouncedValue(value), [delay, value])

  return debouncedValue
}

export default useDebouncedValue
