import { Box, Button, CircularProgress, Divider, Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { COMPANIES_URL, COMPANY_URL } from 'constants/routes'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { createInhouseCompany, editInhouseCompany, getInhouseCompany, uploadLogoCompany } from 'services/companies'
import { CompanyForm, CompanyType } from 'typescript/interfaces/companies'
import SelectComponent from 'UI/Select'
import ReactInputMask from 'react-input-mask'
import RequiredOption from 'UI/Select/RequiredOption'
import { useStates } from 'hooks/useStates'
import { AxiosError } from 'axios'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import UploadImage from 'components/UploadImage'
import { schemaAddEditCompany } from './schema'

const useStyles = makeStyles((theme) => ({
  selectLabel: {
    color: theme.palette.secondary.dark,
  },
  backButton: {
    marginRight: '1.25rem',
  },
  heading: {
    fontSize: '2.25rem',
    color: theme.palette.text.secondary,
    marginBottom: '2rem',
    fontWeight: 500,
  },
  subHeading: {
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
    marginBottom: '2rem',
    fontWeight: 500,
  },
}))

const AddEditCompanyForm = () => {
  const { id } = useParams<{ id?: string }>()
  const { push, goBack } = useHistory()
  const snack = useSnackbar()
  const s = useStyles()
  const { data: statesData } = useStates()

  const { isLoading } = useQuery(['company', +id!], () => getInhouseCompany(id || ''), {
    enabled: !!id,
    onSuccess(res) {
      const fieldsToBeSet: CompanyForm = {
        name: res.data.name,
        id: res.data.id,
        description: res.data.description,
        city: res.data.city,
        street: res.data.street,
        zipCode: res.data.zipCode,
        federalTaxId: res.data.federalTaxId,
        phone: res.data.phone,
        email: res.data.email,
        bankCompanyId: res.data.bankCompanyId,
        bankName: res.data.bankName,
        bankAccountName: res.data.bankAccountName,
        bankAccountNumber: res.data.bankAccountNumber,
        bankRoutingNumber: res.data.bankRoutingNumber,
        companyType: CompanyType.InHouse,
        stateId: res.data.state.id,
        logoUrl: res.data.logo,
      }
      setValues(fieldsToBeSet)
    },
  })

  const [uploadLogoMut, { isLoading: isLoadingUploadLogo }] = useMutation(uploadLogoCompany, {
    onSuccess: () => {
      if (id) {
        push(COMPANY_URL.replace(':id', `${id}`))
        snack.enqueueSnackbar(<Typography>Successfully updated company</Typography>)
      } else {
        snack.enqueueSnackbar(<Typography>Successfully created company</Typography>)
        push(COMPANIES_URL)
      }
    },
    onError: () => {
      snack.enqueueSnackbar('Error while loading image')
    },
  })

  const [mutateCreateCompany, { isLoading: isCreateLoading }] = useMutation(createInhouseCompany, {
    onSuccess(res) {
      if (values.logo) {
        uploadLogoMut({
          companyId: res.data.id || '',
          file: values.logo,
        })
      } else {
        push(COMPANIES_URL)
        snack.enqueueSnackbar(<Typography>Successfully created company</Typography>)
      }
    },
    onError(err: AxiosError) {
      if (err.response?.data.description === 'BANK_ACCOUNT_USED') {
        snack.enqueueSnackbar('Current Bank Account already exists')
      } else {
        snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })
  const [mutateUpdateCompany, { isLoading: isUpdateLoading }] = useMutation(editInhouseCompany, {
    onSuccess(res) {
      // if(values.logo){
      if (values.logo || values.logoUrl)
        uploadLogoMut({
          companyId: id || '',
          file: values.logo || values.logoUrl,
        })
      else {
        push(COMPANY_URL.replace(':id', `${id}`))
        snack.enqueueSnackbar(<Typography>Successfully updated company</Typography>)
      }
      // }
      // else{
      //   push(COMPANY_URL.replace(':id', `${id}`))
      //   snack.enqueueSnackbar(<Typography>Successfully updated company</Typography>)
      // }
    },
    onError(err: AxiosError) {
      if (err.response?.data.description === 'BANK_ACCOUNT_USED') {
        snack.enqueueSnackbar('Current Bank Account already exists')
      } else {
        snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const { values, setValues, handleChange, handleSubmit, errors, touched, resetForm, setFieldValue } = useFormik<CompanyForm>({
    initialValues: {
      name: '',
      description: '',
      city: '',
      street: '',
      zipCode: '',
      federalTaxId: '',
      phone: '',
      email: '',
      stateId: '',
      companyType: CompanyType.InHouse,
      bankCompanyId: '',
      bankName: '',
      bankAccountName: '',
      bankAccountNumber: '',
      bankRoutingNumber: '',
    },
    validationSchema: schemaAddEditCompany,
    onSubmit: (formValues) => {
      if (id) {
        mutateUpdateCompany(formValues)
      } else {
        mutateCreateCompany(formValues)
      }
    },
  })

  useEffect(() => {
    resetForm()
  }, [id])

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="calc(100vh - 98px - 2rem)">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box p="3rem">
            <Typography className={s.heading}>{id ? 'Edit Company' : 'Add New Inhouse Company'}</Typography>
            <Grid spacing={8} container lg={8} xs={12}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Company Name" />}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadImage
                  urlImage={values.logoUrl}
                  handleDelete={() => {
                    setFieldValue('logoUrl', '')
                    setValues({
                      ...values,
                      logoUrl: '',
                      logo: undefined,
                    })
                  }}
                  handleChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      if (e.target.files[0].type.indexOf('image') >= 0) {
                        const reader = new FileReader()
                        reader.onload = (event) => {
                          setFieldValue('logoUrl', event.target?.result)
                        }
                        reader.readAsDataURL(e.target.files[0])
                        setFieldValue('logo', e.target.files[0])
                      } else snack.enqueueSnackbar('Invalid Image Format')
                    }
                  }}
                  label="Choose Logo"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  value={values.description}
                  name="description"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Description" />}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.street && !!errors.street}
                  helperText={touched.street && errors.street}
                  value={values.street}
                  name="street"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Street" />}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  value={values.city}
                  name="city"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="City" />}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectComponent
                  labelProps={{ className: s.selectLabel }}
                  error={!!touched.stateId && !!errors.stateId}
                  helperText={touched.stateId && errors.stateId}
                  value={values.stateId}
                  name="stateId"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="State" />}
                >
                  {statesData?.data.map((state) => (
                    <MenuItem value={state.id}>{state.name}</MenuItem>
                  ))}
                </SelectComponent>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.zipCode && !!errors.zipCode}
                  helperText={touched.zipCode && errors.zipCode}
                  value={values.zipCode}
                  name="zipCode"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Zip Code" />}
                />
              </Grid>
              <Grid item xs={6}>
                <ReactInputMask name="federalTaxId" mask="99-9999999" value={values.federalTaxId} onChange={handleChange}>
                  {(props: any) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      error={!!touched.federalTaxId && !!errors.federalTaxId}
                      helperText={touched.federalTaxId && errors.federalTaxId}
                      fullWidth
                      label={<RequiredOption label="Federal Tax ID" />}
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={6}>
                <ReactInputMask name="phone" mask="(999)-999-9999" value={values.phone} onChange={handleChange}>
                  {(props: any) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      error={!!touched.phone && !!errors.phone}
                      helperText={touched.phone && errors.phone}
                      fullWidth
                      label="Phone"
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Email" />}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box p="3rem">
            <Typography className={s.subHeading}>Bank Information</Typography>
            <Grid spacing={8} container lg={8} xs={12}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.bankCompanyId && !!errors.bankCompanyId}
                  helperText={touched.bankCompanyId && errors.bankCompanyId}
                  value={values.bankCompanyId}
                  name="bankCompanyId"
                  onChange={handleChange}
                  fullWidth
                  label="Bank ID"
                />
              </Grid>
                
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.bankName && !!errors.bankName}
                  helperText={touched.bankName && errors.bankName}
                  value={values.bankName}
                  name="bankName"
                  onChange={handleChange}
                  fullWidth
                  label="Bank Name"
                />
              </Grid>
                
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.bankAccountName && !!errors.bankAccountName}
                  helperText={touched.bankAccountName && errors.bankAccountName}
                  value={values.bankAccountName}
                  name="bankAccountName"
                  onChange={handleChange}
                  fullWidth
                  label="Bank Account Name"
                />
              </Grid>
                
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.bankAccountNumber && !!errors.bankAccountNumber}
                  helperText={touched.bankAccountNumber && errors.bankAccountNumber}
                  value={values.bankAccountNumber}
                  name="bankAccountNumber"
                  onChange={handleChange}
                  fullWidth
                  label="Bank Account Number"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.bankRoutingNumber && !!errors.bankRoutingNumber}
                  helperText={touched.bankRoutingNumber && errors.bankRoutingNumber}
                  value={values.bankRoutingNumber}
                  name="bankRoutingNumber"
                  onChange={handleChange}
                  fullWidth
                  label="Bank Routing Number"
                />
              </Grid>  
            </Grid>
            <Box mt="2rem">
              <Button color="secondary" variant="contained" onClick={() => goBack()} className={s.backButton}>
                Cancel
              </Button>
              <Button disabled={isCreateLoading || isUpdateLoading || isLoadingUploadLogo} color="primary" variant="contained" type="submit">
                Complete
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default AddEditCompanyForm
