// eslint-disable-next-line import/no-self-import
import * as roles from './roles'

export const ADMIN = 'Admin'
export const ISO = 'ISO'
export const REPRESENTATIVE = 'Representative'
export const SYNDICATOR = 'Syndicator'
export const COLLECTOR = 'Collector'
export const FUNDER = 'Funder'
export const ASSISTANT = 'Assistant'
export const CLIENT = 'Client'

export type Role = typeof roles[keyof typeof roles]
