import { IIndustry } from 'typescript/interfaces/industry'
import { IOwnerEntity } from 'typescript/interfaces/owners'
import {
  IClientDocuments,
  IDealsListItem,
  IDocuments,
  IUploadedCategoriesKeys,
  IUploadedClientCategoriesKeys,
  IUploadedClientDocuments,
  IUploadedDocuments,
} from 'typescript/interfaces/deals'
import { ExportType } from 'typescript/enums/export'
import { IPagination } from 'typescript/interfaces/pagination'
import { IBankAccount, IState } from 'typescript/interfaces/companies'

export interface Guarantor {
  id: number
  businessName: string
  ownerName: string
  ein: string
}

export interface IClientListItem {
  id: string
  businessName: string
  joinedOn: Date
  grossAnnualIncome: string
  expectedGrade: string
  actualGrade: string
  repName: string
}

export interface IInitDocsValues {
  documents: IDocuments
  uploadedDocuments: IUploadedDocuments
}

export interface IInitClientDocsValues {
  documents: IClientDocuments
  uploadedDocuments: IUploadedClientDocuments
}

export interface IDocumentToSend {
  file: File
  documentType: IUploadedCategoriesKeys
}

export interface IRemoveFileParams {
  id: string
  name: IUploadedCategoriesKeys
}

export interface IRemoveClientFileParams {
  id: string
  name: IUploadedClientCategoriesKeys
}

export interface IClientDocumentConnectParams {
  documentUrl: string
  description: string
  clientId: number
  documentType: IUploadedCategoriesKeys
}

export interface IClientUploadLinkParams {
  DocumentName: string
  DocumentDestination: 'Client'
  DestinationObjectId: number
}

export interface ClientDocument {
  id: number
  documentUrl: string
  description: string
  createdDate: string
  documentType: IUploadedClientCategoriesKeys
}

export interface IClientListItemLight {
  id: string
  businessName: string
  physicalStateId?: number
}

export interface IClientInitValue {
  clientType: string
  businessName: string
  entityType: string
  doingBusinessAs: string
  businessStartDate: string
  grossAnnualIncome: string | null
  federalTaxId: string
  expectedGrade: string | null
  phone: string
  homePhone: string
  businessPhone: string
  fax: string
  email: string
  webSite: string
  isCrossCollateralize: boolean
  additionalGuarantors: Guarantor[] | null

  industryId: string
  phisicalStateId: string
  city: string
  street: string
  zipCode: string
  mailingStateId: string | null
  mailingCity: string
  mailingStreet: string
  mailingZipCode: string
  landlordOrMortgageCo: string
  landlordContactName: string
  landlordPhone: string
  landlordFax: string
  rentOrMortgagePayment: string
  miCampId: string
  leaseExpiration: string | null
  isMailingAddressDifferent: boolean
  bankAccount: IBankAccount
}

export interface IBankAccountClient {
  isMain: boolean
  bankAccount: IBankAccount
}

export interface ICreateEditBankAccountClientValues extends IBankAccountClient {
  clientId: string | number
}

export interface IDeleteClientBankAccountParams {
  bankAccountId: string | number
  clientId: string | number
}

export interface IClientEntity {
  id: number
  clientType: string
  entityType: string
  joinedOn?: string
  businessName: string
  doingBusinessAs: string
  businessStartDate: Date
  grossAnnualIncome: number
  federalTaxId: string
  expectedGrade: string
  phone: string
  homePhone: string
  businessPhone: string
  fax: string
  email: string
  webSite: string
  isCrossCollateralize: boolean
  additionalGuarantors: Guarantor[] | null
  industry: IIndustry
  industryId: number
  clientId: number
  phisicalStateId: number
  city: string
  street: string
  zipCode: string
  mailingState: IState
  mailingStateId: number | null
  mailingCity: string
  mailingStreet: string
  mailingZipCode: string
  isMailingAddressDifferent: boolean
  landlordOrMortgageCo: string
  landlordContactName: string
  landlordPhone: string
  landlordFax: string
  rentOrMortgagePayment: string
  miCampId: string
  leaseExpiration: Date | null
  repName: string
  owners: Array<IOwnerEntity>
  deals: Array<IDealsListItem>
  documents: Array<ClientDocument>
  bankAccounts: Array<IBankAccountClient>
}

export enum SortFieldsClients {
  id = 'Id',
  legalName = 'BusinessName',
  joinedOn = 'JoinedOn',
  grossAnnualIncome = 'GrossAnnualIncome',
  expectedGrades = 'ExpectedGrade',
  actualGrade = 'ActualGrade',
  repName = 'RepName',
}

export enum ClientType {
  Business = 'Business',
  Personal = 'Personal',
}

export enum EntityType {
  LLC = 'LLC',
  Corporation = 'Corporation',
  Inc = 'Inc',
  SoleProperior = 'SoleProperior',
  LLP = 'LLP',
  LP = 'LP',
}

export enum Grade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export interface IClientExportParams {
  ExportType: ExportType
}

export interface IClientsListParams extends IPagination<SortFieldsClients> {
  Status?: 'Active' | 'Draft'
}

export interface ILightClientListParams extends IClientsListParams {
  IsOnlyApprovedDeals?: boolean
}

export interface IInviteClientInitValues {
  email: string
}

export interface IInviteClientResponse {
  id: number
  email: string
  firstName: string
  lastName: string
  phone: string
  isActive: boolean
  userRoles: {
    id: number
    name: string
  }[]
}

export interface IGetClientBankAccountsParams {
  clientId: string | number
}

export interface ISetMainBankAccountParams {
  clientId: number | string
  bankAccountId: number | string
}

export interface IClientContactInfo {
  businessName?: string
  email?: string
  businessPhone?: string
}
