import React, { FC, useCallback, useMemo, useState } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import { IMerchantPosition } from 'typescript/interfaces/deals'
import { Box, IconButton, makeStyles, TableCell, TableFooter, TableRow, Tooltip } from '@material-ui/core'
import { ReactComponent as DeleteCircleIcon } from 'assets/svg/DeleteCircleIcon.svg'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { useTableFooter } from 'components/Dashboard/style'
import classNames from 'classnames'
import { convertDate, convertEmptyField, converToTwoSignsAfterDot, formatBigNumber } from 'utils/formatters'
import { useQuery } from 'react-query'
import { getDealList } from 'services/deals'
import { DealsStatus } from 'typescript/enums/deals'
import Checkbox from 'UI/Checkbox'
import { PaymentFrequencyEnum, paymentFrequencyInterval } from 'typescript/interfaces/paymentFrequency'
import { getFile } from 'services/storage'
import { createLinkToDownLoad } from 'utils/downloadFile'
import { Link } from 'react-router-dom'
import { SortOrder } from 'typescript/interfaces/pagination'
import { ReactComponent as InfoIcon } from 'assets/svg/InfoIcon.svg'

export const getPositionContractName = (url: string): string | undefined => {
  const match = decodeURIComponent(url).match(/([^/]+?)(?:_[A-Za-z0-9+/=]+)?(\.[^./]+)$/)

  return match ? match[1] + (match[2] || '') : undefined
}

const useStyles = makeStyles(() => ({
  positionControl: {
    padding: 0,
  },
}))

const TablePositions = TableComponent as TableComponentType<IMerchantPosition>

interface IMerchantPositionProps {
  clientId: string
  positions: Array<IMerchantPosition>
  disabled: boolean
  addPosition: (position: IMerchantPosition) => void
  removePosition: (id: number) => void
  editPosition: (data: { event: React.MouseEvent<HTMLButtonElement>; position: IMerchantPosition }) => void
}

const getInternalPositions = async (clientId: string) => {
  const response = await getDealList({
    ClientId: clientId,
    Statuses: [DealsStatus.approved],
    PaidStatuses: ['Current', 'Default', 'InCollection', 'ProcessingRenewal'],
  })

  return response.data.map(
    (x): IMerchantPosition => ({
      id: 0,
      advanceAmount: x.advanceAmount,
      factor: x.factor,
      firstPaymentDate: x.approvedDate,
      remaining: x.remaining,
      daysLeft: Math.ceil(x.remaining / x.frequentPayment) * paymentFrequencyInterval[x.paymentFrequency],
      paymentFrequency: x.paymentFrequency,
      frequentPayment: x.frequentPayment,
      internalDealId: x.id,
      funder: x.company.name,
      dateFunded: x.approvedDate,
      netAmount: x.net,
    }),
  )
}

interface SortingMap {
  [key: string]: (a: IMerchantPosition, b: IMerchantPosition, order?: SortOrder) => number
}

const sortingMap: SortingMap = {
  funder: (a, b, order) => (a.funder || '').localeCompare(b.funder || '') * (order === 'ASC' ? 1 : -1),
  remaining: (a, b, order) => ((a.remaining || 0) - (b.remaining || 0)) * (order === 'ASC' ? 1 : -1),
  defaultInternal: (a, b) => (a.internalDealId || 0) - (b.internalDealId || 0),
  defaultExternal: (a, b) => a.id - b.id,
}

const MerchantPositionsTable: FC<IMerchantPositionProps> = (props) => {
  const classes = useTableComponentStyle()
  const styles = useStyles()
  const { clientId, positions, disabled, addPosition, removePosition, editPosition } = props
  const [sorting, setSorting] = useState<{ order: any; orderBy: SortOrder }>({ order: undefined, orderBy: 'ASC' })

  const { data: internalPositions, isLoading } = useQuery(['merchant-position-deals', clientId], () => getInternalPositions(clientId), {
    enabled: !disabled,
  })

  const selectedInternalDeals = useMemo(() => new Map(positions.map((p) => [p.internalDealId, p.id])), [positions])
  const resultPositions = useMemo(
    () =>
      positions
        .filter((x) => !x.internalDealId || disabled)
        .concat(internalPositions ?? [])
        .sort((a, b) => {
          //external positions first
          if (!a.internalDealId && b.internalDealId) return -1
          if (a.internalDealId && !b.internalDealId) return 1

          const defaultSortingFn = a.internalDealId && b.internalDealId ? sortingMap.defaultInternal : sortingMap.defaultExternal
          const sortingFn = sortingMap[sorting.order]
          return sortingFn?.(a, b, sorting.orderBy) || defaultSortingFn(a, b, sorting.orderBy)
        }),
    [positions, internalPositions, sorting],
  )

  const handleDownLoadFile = useCallback((url, name) => {
    getFile(url).then((res) => {
      createLinkToDownLoad(res.data, name)
    })
  }, [])

  const columns = useMemo<IColumn<IMerchantPosition>[]>(
    () => [
      ...(!disabled
        ? [
            {
              label: '',
              field: 'include',
              cellStyle: { textAlign: 'center' },
              render: (data) =>
                data.internalDealId && (
                  <Checkbox
                    color="primary"
                    className={styles.positionControl}
                    checked={selectedInternalDeals.has(data.internalDealId)}
                    onChange={(e) => {
                      if (e.target.checked) addPosition(data)
                      else removePosition(selectedInternalDeals.get(data.internalDealId) || data.id)
                    }}
                  />
                ),
            } as IColumn<IMerchantPosition>,
          ]
        : []),
      {
        label: 'Funder',
        field: 'funder',
        sortField: 'funder',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => convertEmptyField(data.funder),
      },
      {
        label: 'Advance',
        tooltip: 'Initial Advance Amount',
        field: 'advanceAmount',
        bodyCellClassName: classes.accentCell,
        render: (data) => formatBigNumber(data.advanceAmount),
      },
      {
        label: 'Factor',
        tooltip: 'Factor Rate',
        field: 'factor',
        bodyCellClassName: classes.accentCell,
        render: (data) => converToTwoSignsAfterDot(data.factor),
      },
      {
        label: 'Net',
        tooltip: 'Net Amount Received by Merchant',
        field: 'netAmount',
        bodyCellClassName: classes.accentCell,
        render: (data) => formatBigNumber(data.netAmount),
      },
      {
        label: 'Total RTR',
        field: 'totalRtr',
        bodyCellClassName: classes.accentCell,
        render: (data) => formatBigNumber(Number(data.advanceAmount) * Number(data.factor)),
      },
      {
        label: 'Funded',
        tooltip: 'Date Funded',
        field: 'dateFunded',
        bodyCellClassName: classes.accentCell,
        render: (data) => convertDate(data.dateFunded),
      },
      {
        label: 'First Payment',
        tooltip: 'First Payment Date',
        field: 'firstPaymentDate',
        bodyCellClassName: classes.accentCell,
        render: (data) => convertDate(data.firstPaymentDate),
      },
      {
        label: 'Remaining',
        tooltip: 'Remaining Balance',
        field: 'remaining',
        sortField: 'remaining',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => formatBigNumber(data.remaining),
      },
      {
        label: 'Days Left',
        field: 'daysLeft',
        bodyCellClassName: classes.accentCell,
        render: (data) => convertEmptyField(data.daysLeft),
      },
      {
        label: 'Weekly',
        tooltip: 'Weekly Payment',
        field: 'frequentPaymentWeekly',
        bodyCellClassName: classes.accentCell,
        render: (data) => {
          const displayPayment =
            data.paymentFrequency === PaymentFrequencyEnum.Weekly
              ? data.frequentPayment
              : data.paymentFrequency === PaymentFrequencyEnum.Biweekly && data.frequentPayment
              ? data.frequentPayment / 2
              : 0

          return (
            <Box display="flex" alignItems="center">
              {formatBigNumber(displayPayment)}
              {data.paymentFrequency === 'Biweekly' && (
                <Box mt={0.5} ml={1}>
                  <Tooltip placement="top" title={`Biweekly: ${formatBigNumber(data.frequentPayment)}`}>
                    <InfoIcon color="orange" fill="orange" width={16} height={16} />
                  </Tooltip>
                </Box>
              )}
            </Box>
          )
        },
      },
      {
        label: 'Daily',
        tooltip: 'Daily Payment',
        field: 'frequentPaymentDaily',
        headerCellClassName: classes.headerCell,
        bodyCellClassName: classes.accentCell,
        render: (data) => formatBigNumber(data.paymentFrequency === PaymentFrequencyEnum.Daily ? data.frequentPayment : 0),
      },
      {
        label: 'Contract',
        field: 'contract',
        headerCellClassName: classes.headerCell,
        bodyCellClassName: classes.accentCell,
        render: (data) => {
          if (!data.contractUrl || data.internalDealId) return null

          const documentName = getPositionContractName(data.contractUrl) || 'contract'

          return (
            <Link
              to={data.contractUrl}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                handleDownLoadFile(data.contractUrl, documentName)
              }}
            >
              <Tooltip title={documentName}>
                <Box whiteSpace="nowrap" maxWidth={150} textOverflow="ellipsis" overflow="hidden">
                  {documentName}
                </Box>
              </Tooltip>
            </Link>
          )
        },
      },
      ...(!disabled
        ? [
            {
              label: 'Edit',
              field: 'edit',
              cellStyle: { textAlign: 'center' },
              render: (data) =>
                !data.internalDealId && (
                  <IconButton size="small" onClick={(event) => editPosition({ event, position: data })} className={styles.positionControl}>
                    <EditIcon />
                  </IconButton>
                ),
            } as IColumn<IMerchantPosition>,
            {
              label: 'Delete',
              field: 'remove',
              cellStyle: { textAlign: 'center' },
              render: (data) =>
                !data.internalDealId && (
                  <IconButton size="small" onClick={() => removePosition(data.id)} className={styles.positionControl}>
                    <DeleteCircleIcon />
                  </IconButton>
                ),
            } as IColumn<IMerchantPosition>,
          ]
        : []),
    ],
    [classes, removePosition],
  )

  return (
    <TablePositions
      columns={columns}
      data={resultPositions}
      stickyHeader
      loading={isLoading}
      handleSortChange={(order, orderBy) => setSorting({ order, orderBy })}
      Footer={() => <FooterTableMerchantPositions {...props} />}
    />
  )
}

const FooterTableMerchantPositions: FC<IMerchantPositionProps> = ({ positions, disabled }) => {
  const classes = useTableFooter()
  const totals = useMemo(
    () =>
      positions.reduce(
        (acc, position) => {
          acc.advanceAmount += position.advanceAmount || 0
          acc.remaining += position.remaining || 0
          if (position.frequentPayment) {
            acc.totalDaily += position.paymentFrequency === PaymentFrequencyEnum.Daily ? position.frequentPayment : 0
            acc.totalWeekly +=
              position.paymentFrequency === PaymentFrequencyEnum.Weekly
                ? position.frequentPayment
                : position.paymentFrequency === PaymentFrequencyEnum.Biweekly
                ? position.frequentPayment / 2
                : 0
          }

          return acc
        },
        { advanceAmount: 0, remaining: 0, totalWeekly: 0, totalDaily: 0 },
      ),
    [positions],
  )

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={disabled ? undefined : 2} className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)} />
        <TableCell className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)}>
          {formatBigNumber(totals?.advanceAmount)}
        </TableCell>
        <TableCell colSpan={5} className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)} />
        <TableCell className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)}>{formatBigNumber(totals?.remaining)}</TableCell>
        <TableCell className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)} />
        <TableCell className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)}>
          {formatBigNumber(totals?.totalWeekly)}
        </TableCell>
        <TableCell className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)}>
          {formatBigNumber(totals?.totalDaily)}
        </TableCell>
        <TableCell colSpan={disabled ? undefined : 3} className={classNames(classes.cellStyle, classes.footerTableCell, classes.narrow)} />
      </TableRow>
    </TableFooter>
  )
}

export default MerchantPositionsTable
