import * as yup from 'yup'
import { REQUIRED_FIELD } from 'constants/errors'
import { bankAccountTypes } from 'constants/bankAccountType'
import { integerReg } from 'constants/regExp'
import { bankRoutingSchema, createBankMatchTest, createStateMatchTest } from 'hooks/useValidateBankRouting'

export const schemaClientBankAccount = yup.object().shape({
  bankAccount: yup.object().shape({
    bankRouting: yup
      .string()
      .concat(bankRoutingSchema)
      .concat(createStateMatchTest('bankInfo', 'Client'))
      .concat(createBankMatchTest())
      .required(REQUIRED_FIELD),
    bankRoutingWire: yup
      .string()
      .concat(bankRoutingSchema)
      .concat(createStateMatchTest('bankInfoWire', 'Client'))
      .concat(createBankMatchTest())
      .nullable()
      .notRequired(),
    bankName: yup.string().required(REQUIRED_FIELD),
    bankAccount: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(integerReg, 'Bank Account must be numeric')
      .test('isCorrectLength', 'Must be at least 5 characters and no more than 15', function (val) {
        return Boolean(val && val.length >= 5 && val.length <= 15)
      }),
    bankAccountType: yup.string().oneOf(bankAccountTypes).required(REQUIRED_FIELD),
    bankAccountName: yup.string().required(REQUIRED_FIELD),
  }),
})
