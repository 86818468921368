import React, { FC } from 'react'
import { CircularProgress, InputAdornment, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import { ReactComponent as MarkCheckIcon } from 'assets/svg/MarkCheckIcon.svg'

const useStyles = makeStyles((theme) => ({
  icon: {
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
}))

interface ITextFieldWithPreloaderProps {
  isLoading: boolean
  isValid?: boolean
}

const TextFieldWithPreloader: FC<TextFieldProps & ITextFieldWithPreloaderProps> = ({ isLoading, isValid, value, ...rest }) => {
  const s = useStyles()

  const endAdornment = isLoading ? (
    <InputAdornment position="end">
      <CircularProgress color="primary" size={20} />
    </InputAdornment>
  ) : isValid ? (
    <InputAdornment position="end">
      <MarkCheckIcon className={s.icon} />
    </InputAdornment>
  ) : null

  return <TextField {...rest} value={value} InputProps={{ endAdornment }} />
}

export default TextFieldWithPreloader
