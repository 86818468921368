import React, { useState } from "react";
import { TextField, Grid } from '@material-ui/core';
import Decimal from "decimal.js";

interface Props {
  commision: IIsoCommision
  isoFeeAmount: number | undefined
  isoId : number
  isoName: string
  disabled: boolean | undefined
  mode: SplitIsoCommisionCalcultationMode
  onChange: (id: number, commission: IIsoCommision) => {}
}

interface IIsoCommision {
  percentage: string,
  amount: string
}

enum SplitIsoCommisionCalcultationMode {
  Percentage,
  Amount
}

interface IIsoCommisionDataValidation {
  mode: SplitIsoCommisionCalcultationMode | null,
  validate: (data: string) => {},
  error: string
}

const dataValidations : Array<IIsoCommisionDataValidation> = [
  {
    mode: null,
    validate: (data: string) => {
      const trimmed = data.trim();
      const numberPattern = /^\d+(\.\d+)?$/;

      return numberPattern.test(trimmed) && Number.isFinite(Number(trimmed));
    },
    error: 'Value must be a number.',
  },
  {
    mode: null,
    validate: (data: string) => {
      const value = Number(data);
      return value >= 0;
    },
    error: 'Value cannot be negative.',
  },
  {
    mode: null,
    validate: (data: string) => {
      const value = Number(data);
      return value > 0;
    },
    error: 'Value must be higher than zero.',
  },
  {
    mode: null,
    validate: (data: string) => {
      const value = Number(data);
      return value > 0;
    },
    error: 'Value must be higher than zero.',
  },
  {
    mode: SplitIsoCommisionCalcultationMode.Percentage,
    validate: (data: string) => {
      const value = Decimal(data);
      return value.times(100).mod(1).equals(0);
    },
    error: 'Percentage should be rounded to 2 decimal places or fewer.',
  }
];

export default function SplitIsoCommissionInput ({
    commision,
    disabled,
    mode = SplitIsoCommisionCalcultationMode.Amount,
    isoName,
    isoId,
    isoFeeAmount,
    onChange,
    ...props
}: Props) {
  const [error, setError] = useState<string | null>(null);

  const isError = !!error;
  const value = mode === SplitIsoCommisionCalcultationMode.Percentage ? commision.percentage : commision.amount;
  const validations = dataValidations.filter(v => v.mode === mode || v.mode === null);

  const handlePercentageChanged = (percentage: string) => {
    const perc = new Decimal(percentage);
    const total = new Decimal(isoFeeAmount!);
    const calculatedAmount = perc.dividedBy(100).times(total);

    onChange(
      isoId,
      {
        amount: calculatedAmount.toString(),
        percentage: perc.toString()
      }
    );
  };

  const handleAmountChanged = (amount: string) => {
    const amt = new Decimal(amount);
    const total = new Decimal(isoFeeAmount!);
    const calculatedPercentage = amt.dividedBy(total).times(100);

    onChange(
      isoId,
      {
        amount: amt.toString(),
        percentage: calculatedPercentage.toString()
      }
    );
  };

  const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = e.target.value;

    for (const validation of validations) {
      if (!validation.validate(changedValue)) {
        const errorCommission: IIsoCommision = mode === SplitIsoCommisionCalcultationMode.Percentage
        ? {
          ...commision,
          percentage: changedValue,
        } 
        : {
          ...commision,
          amount: changedValue,
          }
        
        setError(validation.error);

        return onChange(isoId, errorCommission);
      }
    }

    if (isError) {
      setError(null);
    }

    if (mode === SplitIsoCommisionCalcultationMode.Percentage) {
      handlePercentageChanged(changedValue);
    } else {
      handleAmountChanged(changedValue);
    }
  };
  
  const getHelperText = (): string => {
    if (isError) {
      return error!;
    }

    if (mode === SplitIsoCommisionCalcultationMode.Percentage) {
      return `Equivalent Amount: $${commision.amount}`;
    }

    const percentageNumber = new Decimal(commision.percentage);
    const hasMoreThanFourDigitsAfterComa = percentageNumber.times(100).mod(1).greaterThan(0);

    let percentageEquivalent = commision.percentage;

    if (hasMoreThanFourDigitsAfterComa){
      percentageEquivalent = `~${percentageNumber.toFixed(2)}`;
    }

    return `Equivalent Percentage: ${percentageEquivalent}%`;
  } 

  return (
    <Grid container spacing={2}>
      <TextField
        {...props}
        disabled={disabled}
        variant="outlined"
        placeholder={mode === SplitIsoCommisionCalcultationMode.Percentage ? "Percentage" : "Amount"}
        autoComplete={undefined}
        fullWidth
        InputLabelProps={{ shrink: true }}
        error={isError}
        helperText={getHelperText()}
        name={`${isoId}-${SplitIsoCommisionCalcultationMode[mode]}`}
        label={isoName?.trim()}
        key={`commission-${SplitIsoCommisionCalcultationMode[mode]}-${isoId}`}
        value={value}
        onChange={handleChanged}
        />
    </Grid>
  );
}